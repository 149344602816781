import { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import xlsx from "json-as-xlsx"
import axios from 'axios'
import { host } from "../../classes"
import { GlobalContext } from "../../context/GlobalContext"

export const Actual = (props: any) => {
    const auth = useContext(GlobalContext)   
    const [allWork, setAllWork] = useState(false)   
    const [allAplication, setAllAplication] = useState(false)    
    
    const StatusList = ["w8_bloger","w8_seller","cancelled_bloger","cancelled_seller","w8_bloger_feedback","done"]
    


    const downloadFile = () => {
        let content: [{}] = [{}]
        content.shift()
        if(props.item.applications_list)
            props.item.applications_list.filter(
                (item: any) => StatusList.indexOf(item.status) < 0
            ).forEach((item: any, index: number) => {
                content.push({
                    index: ++index,
                    fio: item.bloger.name,
                    link: `https://blogerito.ru/catalog/${item.bloger.pk}/`,
                    status:
                        item.status == "w8_bloger" ? 'Ожидает подтверждения от блогера':
                        item.status == "w8_seller" ? 'Ожидает подтверждения от рекламодателя':
                        item.status == "cancelled_bloger" ? 'Отменено блогером':
                        item.status == "cancelled_seller" ? 'Отменено рекламодателемм':

                        item.status == "in_process" ? 'В процессе выполнения': //1. Заявка принята
                        item.status == "tz_conf" ? 'ТЗ Принято': //2. ТЗ принято
                        item.status == "w8-paydata" ? 'Ожидание реквизитов блогера': //3. Ожидание реквизитов
                        item.status == "payment_w8" ? "Ожидание оплаты (запросите реквизиты у блогера в чате)": //4. Ожидание оплаты
                        item.status == "payment_completed" ? "Оплата проведена": //5. Оплата получена
                        item.status == "product_ordered" ? "Товар заказан": //6 Товар заказан
                        item.status == "product_received" ? "Товар получен": //7 Товар получен
                        item.status == "post_posted" ? "Пост размещен": //8. Пост размещен
                        item.status == "bloger_done" ? 'Блогер подтвердил выполнение интеграции (проверьте работу, подтвердите либо отправьте на доработку)': //9. Успешно завершено
                        item.status == "w8_bloger_feedback" ? 'Ожидание отзыва от блогера':

                        item.status == "done" ? 'Выполнено':"",
                    statusUpdate: item.statusDate
                })
            })

        if(content.length){
            let data = [
                {
                    sheet: "Adults",
                    columns: [
                        { label: "Номер", value: "index" },
                        { label: "Имя блогера", value: "fio" },
                        { label: "Ссылка", value: "link" },
                        { label: "Статус", value: "status" },
                        { label: "Дата обновления статуса", value: "statusUpdate" },
                    ],
                    content: content!
                }, 
            ]
            let settings = {
            fileName: props.item.title,
            }
            xlsx(data, settings)
        }
      }

    useEffect(()=>{
        if(props.item.applications_list){
            if(props.item.applications_list.filter((item: any) => item.status == "w8_seller").length <= 4)
                setAllAplication(true)

            if(props.item.applications_list.filter((item: any) => StatusList.indexOf(item.status) < 0).length <= 4)
                setAllWork(true)
        }
    },[])
    return(
        <>
            <div className="project-list__actual">
                <h2>
                        {props.item.title}      
                </h2>
                <div className="row">
                        <div className="col-md-2">
                            <div 
                                className="project-list_logo" 
                                onClick={()=>{
                                    props.setShowPopUpImage(true)
                                    props.setPopUpURL(`https://api.blogerito.ru${props.item.productImage}`)
                                }}
                                style={
                                    {
                                        cursor: "pointer",
                                        backgroundImage: `url(https://api.blogerito.ru${props.item.productImage})`
                                    }
                                }
                            >
                            </div>
                        </div>
                        <div className="col-md-10">
                            <div className="row project-list__actual-desc">
                                <div className="col-md-6">
                                    <p>
                                        <b>Дата создания: </b>{props.item.date}
                                    </p>
                                    <p>
                                        <b>Всего интеграций: </b>{props.item.maxIntegrations}
                                    </p>
                                    <p style={{textDecoration: "underline"}}>
                                    
                                        <b>Интеграций в работе: </b>{
                                            props.item.applications_list ? ( 
                                                props.item.applications_list.filter(
                                                    (item: any) => StatusList.indexOf(item.status) < 0
                                                ).length
                                            ):""
                                        }
                                    </p>
                                    <p>
                                        <b>Успешно завершено интеграций: </b>{
                                            props.item.applications_list ? ( 
                                                props.item.applications_list.filter(
                                                    (item: any) => item.status == "done" || item.status == "w8_bloger_feedback"
                                                ).length
                                            ):""
                                        }
                                    </p>
                                    <p 
                                        className="project-list__actual-errors"
                                        onClick={()=>{
                                            props.setReportIntegration(props.item.pk)
                                            props.setReportType('integration')
                                            props.setShowReport(true)
                                        }}
                                    >
                                        Проблемы
                                    </p>
                                </div>
                                <div className="col-md-6">
                                    <div className="project-list__actual-btns">
                                        <button 
                                            onClick={()=>{
                                                props.CopyItem(props.item.pk)
                                            }}
                                        >
                                            Копировать
                                        </button> 
                                        <button onClick={()=>{
                                            props.EditItem(props.item)
                                        }}>
                                            Редактировать
                                        </button>
                                        <button 
                                            style={{
                                                backgroundColor: "rgb(58, 12, 163)"
                                            }}
                                            onClick={()=>{
                                                props.CloseItem(props.item.pk)
                                            }}
                                        >
                                            Завершить проект
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>

                <div className="project-list__section">
                    <h3>
                        Все интеграции 
                        <span>
                            Всего необходимо интеграций: {props.item.maxIntegrations}
                            <span>
                                Заявок в работе: {
                                    props.item.applications_list ?(
                                        props.item.applications_list.filter(
                                            (item: any) => StatusList.indexOf(item.status) < 0
                                        ).length
                                    ):""
                                }
                            </span>
                        </span>

                        <button className="error-btn" onClick={()=>{downloadFile()}}>
                            Выгрузить в excel
                        </button>
                    </h3>
                    <table>
                        <tbody>
                            {
                                props.item.applications_list ? (
                                    props.item.applications_list.filter(
                                        (item: any) => StatusList.indexOf(item.status) < 0
                                    ).slice(0,allWork ? props.item.length:5).map((item: any, index: number)=>{
                                        return( 
                                            <tr>
                                                <th>{++index}:</th>
                                                <th>
                                                    <Link target="_blank" to={`/catalog/${item.bloger.pk}/`}>
                                                        {item.bloger.name}. id: {item.bloger.pk}
                                                    </Link>
                                                    <span 
                                                        onClick={()=>{
                                                            props.setApplicationID(item.pk)
                                                            props.setShowChat(true)
                                                        }}
                                                        style={{
                                                            cursor: "pointer",
                                                            fontWeight: 500
                                                        }}
                                                    >
                                                        <svg style={{marginRight: 10}} width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M8 10.5H16" stroke="#1C274C" stroke-width="2" stroke-linecap="round"/>
                                                            <path d="M8 14H13.5" stroke="#1C274C" stroke-width="2" stroke-linecap="round"/>
                                                            <path d="M17 3.33782C15.5291 2.48697 13.8214 2 12 2C6.47715 2 2 6.47715 2 12C2 13.5997 2.37562 15.1116 3.04346 16.4525C3.22094 16.8088 3.28001 17.2161 3.17712 17.6006L2.58151 19.8267C2.32295 20.793 3.20701 21.677 4.17335 21.4185L6.39939 20.8229C6.78393 20.72 7.19121 20.7791 7.54753 20.9565C8.88837 21.6244 10.4003 22 12 22C17.5228 22 22 17.5228 22 12C22 10.1786 21.513 8.47087 20.6622 7" stroke="#1C274C" stroke-width="2" stroke-linecap="round"/>
                                                        </svg>
                                                        Чат
                                                    </span>
                                                </th>
                                                <th>
                                                    Статус: {
                                                        item.status == "w8_bloger" ? 'Ожидает подтверждения от блогера':
                                                        item.status == "w8_seller" ? 'Ожидает подтверждения от рекламодателя':
                                                        item.status == "cancelled_bloger" ? 'Отменено блогером':
                                                        item.status == "cancelled_seller" ? 'Отменено рекламодателемм':
                                                        item.status == "in_process" ? 'Ожидание подтверждения ТЗ': //1. Заявка принята
                                                        item.status == "tz_conf" ? 'ТЗ Принято': //2. ТЗ принято
                                                        item.status == "w8-paydata" ? <>
                                                            Ожидание оплаты ( после того как переведете деньги <span onClick={()=>{props.PayConf(item.pk, props.index)}} style={{textDecoration: "underline", cursor: "pointer"}}>нажмите сюда</span>)
                                                        </>: 
                                                        item.status == "payment_w8" ? "Оплата проведена, ожидание подтверждения от блогера": 
                                                        item.status == "payment_completed" ? "Блогер подтвердил получение оплаты": //5. Оплата получена
                                                        item.status == "product_ordered" ? "Товар заказан": //6 Товар заказан
                                                        item.status == "product_received" ? "Товар получен": //7 Товар получен
                                                        item.status == "post_posted" ? "Пост размещен": //8. Пост размещен
                                                        item.status == "bloger_done" ? <>
                                                            Блогер подтвердил выполнение интеграции (<span style={{textDecoration: "underline", cursor: "pointer"}} onClick={()=>{props.setApplicationID(item.pk);props.setShowChat(true)}}>проверьте работу</span>, подтвердите либо отправьте на доработку)
                                                        </>: //9. Успешно завершено
                                                        item.status == "w8_bloger_feedback" ? 'Ожидание отзыва от блогера':
                                                        item.status == "done" ? 'Успешно завершено':""
                                                    } (Обновлено: {item.statusDate}) 
                                                    <span 
                                                        onClick={()=>{
                                                            props.setReportBloger(item.bloger.pk)
                                                            props.setReportType('bloger')
                                                            props.setReportIntegration(props.item.pk)
                                                            props.setShowReport(true)
                                                        }}
                                                        style={{
                                                            color: "red",
                                                            fontSize: 14,
                                                            cursor: "pointer"
                                                        }}
                                                    >&nbsp;
                                                       Проблема
                                                    </span>
                                                </th>
                                            </tr>
                                        )
                                    })
                                ):""
                            }
                        </tbody>
                    </table>
                    {
                        !allWork ? (
                            <div 
                                style={{cursor: "pointer"}}
                                onClick={()=>{
                                    setAllWork(true)
                                }}
                                className="project-list__more-btn"
                            >
                                Показать все
                            </div>
                        ):""
                    }
                </div>

                <div className="project-list__blogers">
                    <h3>
                        Заявок от блогеров ({
                            props.item.applications_list ?(
                                props.item.applications_list.filter(
                                    (item: any) => item.status == "w8_seller"
                                ).length
                            ):""
                        })
                    </h3>
                    {
                    props.item.applications_list ?( 
                        props.item.applications_list.filter(
                            (item: any) => item.status == "w8_seller"
                        ).slice(0,allAplication ? props.item.length:5).map((item2: any, index: number)=>{
                            return(
                                <div className="row">
                                    <div className="col-md-8 project-list__blogers-content"> 
                                        <Link to={`/catalog/${item2.bloger.pk}/`} target="_blank">{item2.bloger.name}</Link> 
                                        <div 
                                            className="bloger-info__prize-wrapper"
                                            style={{
                                                width: "auto",
                                                display: "inline-block",
                                                marginRight: "10px",
                                                padding: "5px 15px 5px 35px"
                                            }}
                                        >
                                            <p>{item2.bloger.integration}</p>
                                        </div>
                                        ({
                                                Number(item2.bloger.subscribers) < 10000 ? (
                                                    Number(item2.bloger.subscribers) < 2000 ? "Обзорщик":"Наноблогер"
                                                ):"Микроблогер"
                                            } / Отз+: {item2.bloger.goodreview}, Отз-: {item2.bloger.badreview}) предлагает интеграцию
                                    </div>
                                    <div className="col-md-4 project-list__blogers-btns">
                                        <span 
                                            style={{color: "green"}}
                                            onClick={()=>{
                                                props.RespondItem(props.item.pk, true, item2.bloger.pk, props.index)
                                            }}
                                        >
                                            Принять
                                        </span>
                                        <span 
                                            style={{
                                                color: "rgb(209, 3, 3)"
                                            }}
                                            onClick={()=>{
                                                props.RespondItem(props.item.pk, false, item2.bloger.pk, props.index)
                                            }}
                                        >
                                            Отклонить
                                        </span>
                                    </div>
                                </div>
                            )
                        })
                    ):""
                    } 
                    {
                        !allAplication ? (
                            <div 
                                className="project-list__more-btn"
                                style={{cursor: "pointer"}}
                                onClick={()=>{
                                    setAllAplication(true)
                                }}
                            >
                                Показать все
                            </div>
                        ):""
                    }
                </div>

            </div>
        </>
    )
}