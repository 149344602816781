import React, { useContext, useState, useEffect } from "react"
import { GlobalContext } from "../context/GlobalContext" 
import { PayLog, Seller } from "../classes"  
import { Loader } from "../component/Loader"
import { Navigation } from "../component/Navigation"

export const PayStoryPage = () => {   
    const auth = useContext(GlobalContext)   
    const [load, setLoad] = useState(true)  
    const [payType, setPayType] = useState("30")
    const [payList, setPayList] = useState(new PayLog()) 
    
    const clickIvent = (target: number) => {
        payList.targetPage = target
        setLoad(true)
        payList.LoadCatalog(auth.user.userToken).then(function(response){
            setLoad(false)
        }).catch(function(error){
            alert("error")
        })
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault()
        if(!load){
            setLoad(true)
            if(auth.user.typeObject instanceof Seller)
                auth.user.typeObject!.NewPay(payType).then(function(respons){
                    window.location.replace(respons.msg);
                }).catch(function(error){
                    alert("error")
                })
        }
    }

    useEffect(()=>{
        payList.LoadCatalog(auth.user.userToken).then(function(respons){
            if(auth.user.typeObject instanceof Seller)
                auth.user.typeObject!.LoadScore().then(function(respons){
                    setLoad(false)
            }).catch(function(error){
                setLoad(false)
                alert("error")
            })
        }).catch(function(error){
            setLoad(false)
            alert("error")
        })
    },[])

    if(load){
        return(
            <Loader /> 
        )
    }

    return( 
        <>
            <section className="pay-list">
                <div className="content">
                    {
                        auth.user.typeObject instanceof Seller ? (
                            <div className="user-score">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h3>Баланс аккаунта</h3> 
                                        
                                        <p>
                                            <b>Подписка действует до:</b> {auth.user.typeObject.subscribeDate}
                                        </p> 
                                        <p>
                                            <b>Осталось интеграций:</b> {auth.user.typeObject.integrationsCount}
                                        </p>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="grid">                                
                                            <form onSubmit={handleSubmit} className="pay-form">
                                                <label> 
                                                    Количество интеграций в месяц
                                                    <select onChange={(e)=>{
                                                        setPayType(e.target.value)
                                                    }} >
                                                        <option value={"30"}>30 интеграций (5000 руб)</option>
                                                        <option value={"60"}>60 интеграций (8000 руб)</option>
                                                        <option value={"100"}>100 интеграций (12000 руб)</option>                                                
                                                    </select>
                                                </label>
                                                <button>
                                                    Пополнить
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ):""
                    }
                    <h2 className="title ftitle">История денежных операций</h2>
                    {
                        payList.payList ? (
                            payList.payList.length ? (
                                payList.payList.map((item, index)=>{
                                    return (
                                        <div key={index} className="pay-list__item">
                                            <b>Название: </b>{item.title}<br/>
                                            <b>Дата: </b>{item.date}<br/>
                                            <b>Сумма операции: </b>{item.count} руб<br/>
                                        </div>
                                    )
                                })  
                            ):(
                                <div>
                                    <h3>Список пуст</h3>
                                </div>
                            ) 
                        ):(
                            <div>
                                <h3>Список пуст</h3>
                            </div>
                        )
                    }

                    <Navigation 
                        countPage={payList.countPage}
                        targetPage={payList.targetPage}
                        clickIvent={clickIvent}
                    />

                </div>
            </section>
        </>
    )
}