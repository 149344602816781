import React, {useContext, useEffect, useRef, useState} from "react"
import { GlobalContext } from "../context/GlobalContext" 
import { Link } from "react-router-dom"
import axios from "axios"

import socInstagram from '../image/soc-instagram.svg'
import socYoutube from '../image/soc-youtube.svg'
import socVk from '../image/soc-vk.svg'
import socRutube from '../image/soc-rutube.svg'
import socTelegram from '../image/soc-telegram.svg'

export const CatalogItem = (props: any) => {
    const auth = useContext(GlobalContext) 

    interface IntegrationInterface {
        title: string
        pk: string
    }   
    const [form, setForm] = useState({ 
        integration: "",
        type: "" ,
        format1: true,
        format2: false,
        format3: false,
        format4: false,
        format5: false,
    })
    const [showFormat, setShowFormat] = useState(false)  

    const myRefNotification = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const checkIfClickedOutside = (e: any) => { 
          if (showFormat && myRefNotification.current && !myRefNotification.current.contains(e.target)) { 
            setShowFormat(false)
          }
        }
        document.addEventListener("mousedown", checkIfClickedOutside) 
        return () => { 
          document.removeEventListener("mousedown", checkIfClickedOutside)
        }
      }, [showFormat])

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault()
        //props.setLoad(true)
        
        const formData = new FormData()
        formData.append("integration", form.integration)
        formData.append("bloger", props.item.pk)
        formData.append("type", `${form.format1 ? "Рекламный пост, " : "" }${form.format2 ? "Выкуп на маркетплейсе, " : "" }${form.format3 ? "Отзыв на маркетплейсе, " : "" }${form.format5 ? "Видеоотзыв на маркетплейсе " : "" }${form.format4 ? "Раздача товаров подписчикам " : "" }`)


        await axios.post(`https://api.blogerito.ru/integration-applications/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Token '+auth.user.userToken
            }
        }).then(res => { 
            //props.setLoad(false) 
            props.setPopupType(res.data.msg)
            props.setPopupError(res.data.error)
            props.setShowPopup(false)
        }).catch(error => {
            console.log(error)
            alert("error")
            props.setLoad(false)
        })
    }

    return(
        <form  onSubmit={handleSubmit} className="catalog-bloger">
            <div className="row">
                <div className="col-md-2">
                    <div className="bloger-info__prize">
                        <div className="bloger-info__prize-wrapper">
                            <p>{props.item.integration}</p>
                        </div>
                    </div> 
                    <div className="catalog__userpic" style={{backgroundImage: `url(http://api.blogerito.ru/${props.item.image})`}}></div>
                </div>

                <div className="col-md-10">
                    <div className="row">
                        <div className="col-md-4">
                            <h2 className="bloger-info__title">
                                <Link to={`/catalog/${props.item.pk}/`}>
                                    {props.item.name}
                                </Link>
                            </h2>
                            <p className="catalog__active" style={{paddingBottom: "30px"}}>
                                {props.item.CatalogConf == true ? (<>Аккаунт подтвержден<br/></>): ""}
                                {props.item.dateActivity}
                            </p>
                            <p className="catalog__desc">{props.item.minidesc}</p>
                            <p className="bloger-info__category">{props.item.category}</p>
                            <div className="catalog__soc">
                                {
                                    props.item.instagram != null ? (
                                        <img src={socInstagram} /> 
                                    ):""
                                }
                                {
                                    props.item.youtube != null ? (
                                        <img src={socYoutube} /> 
                                    ):""
                                }
                                {
                                    props.item.vk != null ? (
                                        <img src={socVk} /> 
                                    ):""
                                }
                                {
                                    props.item.rutube != null ? (
                                        <img src={socRutube} /> 
                                    ):""
                                }
                                {
                                    props.item.telegram != null ? (
                                        <img src={socTelegram} /> 
                                    ):""
                                }
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="sub-count">
                                <h3>
                                    {
                                        props.item.subscribers < 10000 ? (
                                            props.item.subscribers < 2000 ? "Обзорщик":"Наноблогер"
                                        ):"Микроблогер"
                                    }
                                </h3>
                            </div>

                            <div className="sub-prog">
                                <div className="sub-prog__img">
                                    <div className="one">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path d="M10.0002 0C7.41404 0 5.32045 2.0936 5.32045 4.6798C5.32045 7.26601 7.41404 9.35961 10.0002 9.35961C12.5865 9.35961 14.6801 7.26601 14.6801 4.6798C14.6801 2.0936 12.5865 0 10.0002 0ZM11.8229 9.90148H8.17759C4.50764 9.90148 1.52734 12.8818 1.52734 16.5517V19.2611C1.52734 19.6798 1.84754 20 2.26626 20H17.7342C18.153 20 18.4732 19.6798 18.4732 19.2611V16.5517C18.4732 12.8818 15.4929 9.90148 11.8229 9.90148Z" fill="#4361EE"></path>
                                        </svg>
                                    </div>
                                    <div className="two">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <g clip-path="url(#clip0_121_4626)">
                                                <path d="M16.4525 15.0375L13.26 14.2392L13.0758 13.5017C15.2783 13.1309 16.4116 12.4709 16.4616 12.4417C16.58 12.3725 16.6533 12.2467 16.6633 12.11C16.6725 11.9733 16.6116 11.8392 16.5041 11.7533C16.4892 11.7417 15 10.5117 15 6.25C15 2.65582 14.1592 0.83332 12.5 0.83332H12.2542C11.6558 0.255 11.2067 0 10 0C8.43418 0 5 1.57418 5 6.25C5 10.5117 3.51082 11.7417 3.5 11.75C3.44543 11.791 3.4017 11.8446 3.37265 11.9064C3.3436 11.9681 3.33009 12.036 3.33332 12.1042C3.33681 12.1724 3.35703 12.2387 3.3922 12.2973C3.42738 12.3559 3.47642 12.4049 3.535 12.44C3.585 12.47 4.7075 13.1333 6.92332 13.5033L6.73914 14.2391L3.54668 15.0375C1.45832 15.56 0 17.4283 0 19.5833C0 19.8133 0.18668 20 0.41668 20H19.5834C19.8134 20 20 19.8117 20 19.5817C20.0013 18.5368 19.6527 17.5216 19.0097 16.698C18.3667 15.8744 17.4664 15.2898 16.4525 15.0375Z" fill="#DD79DB"></path>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_121_4626">
                                                    <rect width="20" height="20" fill="white"></rect>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                </div>
                                <div className="sub-prog__progerss">
                                    <div style={{width: `${props.item.men}%`}}></div>
                                </div> 
                            </div>
                                            
                            <div className="sub-prog__rew">
                                <div>
                                    <p>Отзывы</p>
                                </div>
                                <div>
                                    <div className="sub-prog__proc">
                                        <div style={{borderRight: "1.5px solid #3A0CA3"}}>{props.item.goodreview}+</div>
                                        <div>{props.item.badreview}-</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="catalog__integrations">
                                <h3>Заказать интеграцию:</h3>
                                <label>                                        
                                    <select required onChange={(e)=>{ 
                                        setForm({ ...form, "integration": e.target.value })
                                    }}>
                                        <option value="" disabled selected>Интеграция</option>
                                        {
                                            props.integrationList.map((item: IntegrationInterface, index: number)=>{
                                                return (
                                                    <option value={item.pk}>{item.title}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </label>
            

                                <div>
                                    <div className="catalog-selecter__sellect">
                                        <div className="catalog-selecter__text" onClick={()=>{setShowFormat(!showFormat)}}>
                                            <div>
                                                {!form.format1 && !form.format2 && !form.format3 && !form.format4 && !form.format5 ? "Форматы размещения" : (
                                                    <>
                                                        {form.format1 ? "Рекламный пост, " : "" }
                                                        {form.format2 ? "Выкуп на маркетплейсе, " : "" }
                                                        {form.format3 ? "Отзыв на маркетплейсе, " : "" }
                                                        {form.format5 ? "Видеоотзыв на маркетплейсе, " : "" }
                                                        {form.format4 ? "Раздача товаров подписчикам " : "" }
                                                    </>
                                                )}      
                                            </div>                                              
                                        </div>
                                        {
                                            showFormat ? (
                                                <div ref={myRefNotification} className="catalog-selecter__options">
                                                    <label className="type-sellect__item">
                                                        <input type="checkbox" name="format1" checked={form.format1 ? true:false} onChange={(e)=>{
                                                            setForm({ ...form, [e.target.name]: e.target.checked })
                                                        }} />
                                                        Рекламный пост
                                                    </label>
                                                    <label className="type-sellect__item">
                                                        <input type="checkbox" name="format2" checked={form.format2 ? true:false} onChange={(e)=>{
                                                            setForm({ ...form, [e.target.name]: e.target.checked })
                                                        }} />
                                                        Выкуп на маркетплейсе
                                                    </label>
                                                    <label className="type-sellect__item">
                                                        <input type="checkbox" name="format3" checked={form.format3 ? true:false} onChange={(e)=>{
                                                            setForm({ ...form, [e.target.name]: e.target.checked })
                                                        }} />
                                                        Отзыв на маркетплейсе
                                                    </label>
                                                    <label className="type-sellect__item">
                                                        <input type="checkbox" name="format5" checked={form.format5 ? true:false} onChange={(e)=>{
                                                            setForm({ ...form, [e.target.name]: e.target.checked })
                                                        }} />
                                                        Видеоотзыв на маркетплейсе
                                                    </label>
                                                    <label className="type-sellect__item">
                                                        <input type="checkbox" name="format4" checked={form.format4 ? true:false} onChange={(e)=>{
                                                            setForm({ ...form, [e.target.name]: e.target.checked })
                                                        }} />
                                                        Раздача товаров подписчикам
                                                    </label>
                                                </div>
                                            ):''
                                        }                                
                                    </div>
                                </div>                    
                                <p>Стоимость интеграции</p>
                            </div>
                            <div className="catalog-cost">
                                <h3>{props.item.integrationCost} <span>руб.</span></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button className="catalog-more">
                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
                    <g clip-path="url(#clip0_95_2295)">
                        <path d="M12.2687 15.7705L11.4973 13.5885L7.1719 15.4205C7.15831 15.4263 7.14472 15.4324 7.13163 15.439C6.49825 15.7557 6.10179 16.2226 6.01581 16.7534C5.941 17.2138 6.10348 17.6713 6.47352 18.0413L6.47365 18.0414C6.64883 18.2166 6.87024 18.3717 7.13166 18.5024C7.14485 18.5091 7.15831 18.5153 7.17177 18.521L11.4972 20.353L12.2688 18.1711C12.4749 17.5877 12.475 16.3537 12.2687 15.7705ZM33.1828 17.6737L13.7947 17.6737C13.7574 18.0329 13.6907 18.3676 13.5947 18.6398L10.9027 26.253C10.5411 27.2758 10.8736 27.8149 11.1038 28.0451L11.1039 28.0452C11.3097 28.2509 11.9074 28.6697 13.013 28.117L32.242 18.5024C32.6732 18.2866 32.9943 18.0013 33.1828 17.6737L33.1828 17.6737ZM13.7946 16.2675L33.1837 16.2675C33.1096 16.1383 33.0149 16.015 32.9 15.9002C32.7246 15.7247 32.5032 15.5696 32.2418 15.439L13.013 5.82441C11.9955 5.31586 11.4123 5.62093 11.1553 5.84791C10.8982 6.07503 10.5236 6.61606 10.9028 7.68826L13.5946 15.3016C13.6905 15.5737 13.7572 15.9084 13.7945 16.2675H13.7946Z" fill="white"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_95_2295">
                            <rect width="24" height="24" fill="white" transform="translate(17) rotate(45)"/>
                        </clipPath>
                    </defs>
                </svg>
            </button>
        </form>
    )
}