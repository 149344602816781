import React, { ChangeEvent, useContext, useState, useEffect } from "react"
import { GlobalContext } from "../../../context/GlobalContext"  
import { Seller, TemplatesMsg, host } from "../../../classes"
import axios from 'axios'
import QRCode from "qrcode"
import { NotificationForm } from "../../../component/NotificationForm"
import { Loader } from "../../../component/Loader"

export const SellerSettingPage = (props: any) => {
    const auth = useContext(GlobalContext)   
    const [load, setLoad] = useState(true) 
    const [loadForm, setLoadForm] = useState(true) 
    const [loadTempForm, setLoadTempForm] = useState(true) 
    const [loadTempForm2, setLoadTempForm2] = useState(false) 
    
    const [templatesMsg, setTemplatesMsg] = useState(new TemplatesMsg())
    
    const [popupType, setPopupType] = useState("") 
    const [popupError, setPopupError] = useState(true) 
    const [qrImage, setQrImage] = useState("") 
    const [form, setForm] = useState({ 
        name: "",
        bDate: "", 
        minidesc: "", 
        phone: "", 
        desc: "",
        doc: ""
    })
    const [notifications, setNotifications] = useState({
        notificationsType: "",
        notificationsEmail: ""
    })

    useEffect(()=>{ 
        templatesMsg.getData(auth.user.userToken).then(function(response){
            setLoadTempForm2(false)
        }).catch(function(error){
            alert("error")
        })
        auth.user.typeObject!.LoadData(auth.user).then(function(response){
            setLoad(false)
            if(auth.user.typeObject instanceof Seller){
                setNotifications({
                    notificationsType: auth.user.typeObject.sendNotificationsType,
                    notificationsEmail: auth.user.typeObject.notificationsMail,
                })

                setForm({
                    name: auth.user.userName,
                    bDate: auth.user.typeObject.dopInfo.bDate,
                    minidesc: auth.user.typeObject.dopInfo.minidesc, 
                    phone: auth.user.typeObject.dopInfo.phone, 
                    desc: auth.user.typeObject.dopInfo.desc, 
                    doc: auth.user.typeObject.dopInfo.doc, 
                }) 

                QRCode.toDataURL(`tg://resolve?domain=blogerito_bot&start=${auth.user.typeObject?.secretKey}`).then((val) => {
                    setQrImage(val)
                })
            }
        })        
    },[])


    const [formPassword, setFormPassword] = useState({
        password: "",
        cPassword: ""
    })
    
    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }

    const changeHandlerForm = (event: ChangeEvent<HTMLInputElement>) => {
        setFormPassword({ ...formPassword, [event.target.name]: event.target.value })
    }


    const handleSubmitPassword = (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault()
        if(loadForm){
            setLoadForm(false)
            auth.user.UpdatePassword(formPassword.password, formPassword.cPassword).then(function(response){
                setPopupType(response.msg)
                setPopupError(response.error)
                setLoadForm(true)
            }).catch(function(error){
                setLoadForm(true)
                alert("error")
            })
        } 
    }

    function handlerImage(e: any){
        setForm({ ...form, ["doc"]: e.target.files[0] })
        console.log(e.target.files)
    }

    const [formTemp, setFormTemp] = useState({
        title: "",
        minidesc: ""
    })

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault()
        if(loadForm){
            setLoadForm(false)
            
            if(auth.user.typeObject instanceof Seller){
                auth.user.typeObject.SaveData(form).then(function(response){
                    setPopupType(response.msg)
                    setPopupError(response.error)
                    setLoadForm(true)
                }).catch(function(error){
                    setLoadForm(true)
                    alert("error")
                })
            }
        }
    }

    if(load){
        return(
            <Loader /> 
        )
    }


    return (
        <>
            <section className="bloger-info">
                <div className="content">
                    <div className="row">
                        <div className="col-md-3 order1">
                            <div className="bloger-info__userpic" style={{backgroundImage: "url('"+auth.user.image+"')"}}></div>
                            <label className="dop-file">
                                Изменить фото
                                <input type="file" style={{display: "none"}} accept="image/*" onChange={(e)=>{
                                    if(loadForm){
                                        setLoadForm(false) 
                                        if(e.target.files){  
                                            auth.user.SaveUserpic(e.target.files[0]).then(function(response){
                                                setPopupType("Фото обновлено")
                                                setPopupError(response.error)
                                                setLoadForm(true)
                                            }).catch(function(error){
                                                alert("error")
                                                setLoadForm(true)
                                            })
                                        }
                                    }
                                }} />
                            </label>
                        </div>

                        <div className="col-md-9 order2">
                            <h2 className="title ftitle">Настройки аккаунта</h2>
                            <form className="form-item__wrapper" onSubmit={handleSubmit}>
                                <label>
                                    ФИО*
                                    <input maxLength={255} type="text" required name="name" value={form.name} onChange={changeHandler} />
                                </label>

                                <label>
                                    Дата рождения*
                                    <input type="date" required name="bDate" value={form.bDate} onChange={changeHandler} />
                                </label>

                                <label>
                                    Краткий текст о вас*
                                    <textarea maxLength={510} required name="minidesc" value={form.minidesc} onChange={(e)=>{
                                        setForm({ ...form, "minidesc": e.target.value })
                                    }} />
                                </label>

                                <label>
                                    Номер телефона*
                                    <input maxLength={255} type="text" required name="phone" value={form.phone} onChange={changeHandler} />
                                </label>

                                <label>
                                    Документ
                                    <input type="file" name="doc"  onChange={handlerImage} accept="image/*" style={{color: "black"}} />
                                </label>

                                <label>
                                    Полный текст о вас*
                                    <textarea  required name="desc" value={form.desc} onChange={(e)=>{
                                        setForm({ ...form, "desc": e.target.value })
                                    }} />
                                </label> 
                                <button>Обновить</button> 
                            </form>

                            <NotificationForm 
                                notifications={notifications} 
                                setNotifications={setNotifications} 
                                qrImage={qrImage}  
                                loadForm={loadForm}  
                                setLoadForm={setLoadForm}  
                                popupType={popupType}
                                setPopupType={setPopupType}
                                popupError={popupError} 
                                setPopupError={setPopupError}
                            />
                            
                            <h2 className="title" style={{marginTop: 42}}>Изменить пароль</h2>  
                            <form className="form-item__wrapper" onSubmit={handleSubmitPassword}>
                                <label>
                                    Пароль
                                    <input type="password" required name="password" value={formPassword.password} onChange={changeHandlerForm} />
                                </label>
                                <label>
                                    Подтвердите пароль
                                    <input type="password" required name="cPassword" value={formPassword.cPassword} onChange={changeHandlerForm} />
                                </label>

                                <button>Обновить</button>
                            </form>


                            <h2 className="title" style={{marginTop: 42}}>Шаблоны текста сообщений</h2>  
                            <div className="temp-list">
                                {
                                    loadTempForm2 ? (
                                        <Loader /> 
                                    ):(
                                        templatesMsg.list.length ? (
                                            templatesMsg.list.map((item, index) => {
                                                return(
                                                    <div className="temp-item" key={index}>
                                                        <h3>{item.title}</h3>
                                                        <p>{item.minidesc}</p>
                                                        <div
                                                            onClick={()=>{
                                                                setLoadTempForm2(true)
                                                                item.delData(auth.user.userToken).then(function(response){
                                                                    setPopupType("Шаблон успешно удален")
                                                                    templatesMsg.getData(auth.user.userToken).then(function(response){
                                                                        setLoadTempForm2(false)
                                                                    }).catch(function(error){
                                                                        alert("error")
                                                                    })
                                                                }).catch(function(error){
                                                                    alert("error")
                                                                })
                                                            }}
                                                            style={{cursor: "pointer"}}
                                                        >Удалить</div>
                                                    </div>
                                                )
                                            })
                                        ):""
                                    )
                                }   
                            </div>
                            <h4 style={{marginTop: 40, fontSize: 24}}>Добавить шаблон</h4>
                            <form className="form-item__wrapper" onSubmit={async (e)=>{
                                e.preventDefault()
                                if(loadTempForm){
                                    setLoadTempForm(false)
                                    setLoadTempForm2(true)
                                    templatesMsg.addData(formTemp.title, formTemp.minidesc, auth.user.userToken).then(function(response){
                                        setPopupType("Шаблон успешно создан")
                                        templatesMsg.getData(auth.user.userToken).then(function(response){
                                            setLoadTempForm(true)
                                            setLoadTempForm2(false)
                                        }).catch(function(error){
                                            alert("error")
                                        })
                                    }).catch(function(error){
                                        alert("error")
                                    })
                                }
                            }}>
                                <label>
                                    Название
                                    <input type="text" required name="title" value={formTemp.title} onChange={(e)=>{
                                        setFormTemp({ ...formTemp, [e.target.name]: e.target.value })
                                    }} />
                                </label>
                                <label>
                                    Текст сообщения

                                    <textarea maxLength={510} required name="minidesc" value={formTemp.minidesc} onChange={(e)=>{
                                        setFormTemp({ ...formTemp, [e.target.name]: e.target.value })
                                    }} />
                                </label>

                                <button>Создать</button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>


            { 
                popupType != "" ? (                                 
                    <div className="popup-msg">
                        <div className="popup-msg__body">
                            <h3>{popupType}</h3>
                            <div className="popup-msg__link" onClick={()=>{
                                setPopupType("") 
                            }}>
                                Продолжить
                            </div>
                        </div>
                    </div>
                ):""
            }     
        </>
    )

}