import React, { useContext, useState } from "react"
import { GlobalContext } from "../../context/GlobalContext" 
import { BlogerApplicationPage } from "./BlogerApplicationPage/BlogerApplicationPage" 

export const ApplicationPage = () => {  
    const auth = useContext(GlobalContext)    
    
    return( 
        <>
            {
                auth.user.userType == "bloger" ? (
                    <BlogerApplicationPage />
                ):(
                    <div>0</div>
                )
            } 
        </>
    )
}