import React, { useContext, useState } from "react"
import { GlobalContext } from "../../context/GlobalContext" 
import { BlogerDBPage } from "./BlogerDBPage/BlogerDBPage"
import { SellerDBPage } from "./SellerDBPage/SellerDBPage"

export const DBPage = () => {  
    const auth = useContext(GlobalContext)  
    return( 
        <>
            {
                auth.user.userType == "bloger" ? (
                    <BlogerDBPage />
                ):(
                    <SellerDBPage  />
                )
            } 
        </>
    )
}