import React, { useContext, useState, useEffect } from "react"
import { GlobalContext } from "../context/GlobalContext" 
import { Chat, TemplatesMsg } from "../classes"
import { Loader } from "../component/Loader" 
import ScrollToBottom from 'react-scroll-to-bottom';
import { Link } from "react-router-dom";

export const ChatPage = () => {  
    const auth = useContext(GlobalContext)   
    const [load, setLoad] = useState(true) 
    const [loadMsg, setLoadMsg] = useState(false) 
    const [showPopup, setShowPopup] = useState(false) 
    const [stars, setStars] = useState(5) 
    const [fileName, setFileName] = useState("")  
    const [targetChat, setTargetChat] = useState(0) 
    const [msg, setMsg] = useState("") 
    const [msgFile, setMsgFile] = useState("") 
    const [updateTest, setUpdateTest] = useState(false) 
    const [popupType, setPopupType] = useState("") 
    const [comSeller, setComSeller] = useState("") 
    const [chat, setChat] = useState(new Chat())

    const [templatesMsg, setTemplatesMsg] = useState(new TemplatesMsg())
    
    const [targetName, setTargetName] = useState("")
    const [targetStep, setTargetStep] = useState("")

    const [conselReason, setConselReason] = useState("") 
    
    const [update, setUpdate] = useState(false)
    const [chatMsg, setChatMsh] = useState([{}])

    const queryParams = new URLSearchParams(window.location.search);
    useEffect(()=>{
        if(auth.user.userType == "seller"){
            templatesMsg.getData(auth.user.userToken).then(function(response){
            }).catch(function(error){
                alert("error")
            })
        }

        chat.UpdateList(auth.user.userToken).then(response => {
            console.log(chat.chatList)
            chat.chatList.forEach((item: any, index)=>{
                if(
                    (
                        item.integration.pk == Number(queryParams.get('integration')) && 
                        Number(item.applicate.bloger.pk) == Number(queryParams.get('bloger')) 
                    ) || (
                        item.integration.pk == Number(queryParams.get('integration')) && 
                        Number(item.integration.seller.pk) == Number(queryParams.get('seller'))  
                    )
                ){
                    if(!loadMsg){
                        setTargetChat(index)
                        //setLoad(true)
                        setLoadMsg(true)
                        item.blogerOpen = true
                        item.sellerOpen = true
                        chat.UpdateChatItem(auth.user.userToken, item.pk, item.applicate.status).then(response =>{
                            //setLoad(false) 
                            setChatMsh(chat.chatMsg)
                            setLoadMsg(false)
                        }).catch(error => {
                            alert("error")
                        })
                    }
                }
            })
            console.log(queryParams.get('integration'))
            console.log(queryParams.get('bloger'))

            chat.UpdateChatItem(auth.user.userToken, chat.targetChat, chat.targetStatus).then(response =>{
                setChatMsh(chat.chatMsg)
                setLoad(false)
                const timer = setInterval(() => {  
                    if(chat.targetChat != 0)
                        chat.UpdateChatItem(auth.user.userToken, chat.targetChat, chat.targetStatus).then(response =>{
                            if(chat.chatMsg.length >= chatMsg.length)
                                setChatMsh(chat.chatMsg)
                            console.log("update") 
                            setUpdate(!update)
                            setChat(chat)
                            setUpdateTest(!updateTest)
                        }).catch(error => {
                            console.log("error")
                        })
                }, 20000);
            }).catch(error => {
                console.log("error")
            })
        }).catch(error => {
            alert("error")
        })
 
    },[])


    useEffect(() => {
        console.log(msg)
        
    },[msg])

    const showMessage = (msg: string) => { 
        console.log(msg)
        console.log(chat.targetStatus)
        if(msg != "" && (
            chat.targetStatus != "cancelled_bloger" &&
            chat.targetStatus != "cancelled_seller" &&
            chat.targetStatus != "w8_bloger_feedback" &&
            chat.targetStatus != "done"
        ))
            if(!load){ 
                const cMsg = msg
                chat.chatMsg.push({
                    "status": auth.user.userType == "bloger" ? "Блогер" : "Рекламодатель",
                    "image": "",
                    "date": "",
                    "minidesc": cMsg
                }) 
                setChatMsh(chat.chatMsg)
                setMsg("") 

                chat.SendMsg(auth.user.userToken, cMsg, msgFile).then(function(response){ 
                    //setMsg("")
                    setMsgFile("") 
                    setFileName("")
                }).catch(function(error){
                    alert("error")
                }) 
            }
    };
    const keydownHandler = (e: any) => {
        if(e.key === 'Enter' && e.ctrlKey) showMessage(msg)
    };
    React.useEffect(() => {
        document.addEventListener('keydown', keydownHandler);
        return () => {
          document.removeEventListener('keydown', keydownHandler);
        }
    }, [msg]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault()
        if(!load){ 
            const cMsg = msg == "" ? "&nbsp;" : msg
            chat.chatMsg.push({
                "status": auth.user.userType == "bloger" ? "Блогер" : "Рекламодатель",
                "image": "",
                "date": "",
                "minidesc": cMsg
            }) 
            setChatMsh(chat.chatMsg)
            setMsg(" ") 

            const hItem = chat.chatList[targetChat]
            chat.chatList.splice(targetChat, 1)
            chat.chatList.unshift(hItem)

            

            chat.SendMsg(auth.user.userToken, cMsg, msgFile).then(function(response){ 
                setMsg("")
                setMsgFile("") 
                setFileName("")
            }).catch(function(error){
                alert("error")
            })

        }
    }

    if(load){
        return(
            <Loader /> 
        )
    }

    return( 
        <>
            <section className="chat">
                <div className="content"> 
                    <h1 className="title" style={{marginTop: 0}}>Чат</h1> 
                    <div className="row">
                        <div className="col-md-4">
                            {
                                auth.user.userType != "bloger" ? (
                                    <>
                                    <select className="chatName" onChange={(e)=>{setTargetName(e.target.value)}}>
                                        <option value={""}>Все интеграции</option>
                                        {
                                            chat.chatNames.map((item, index)=>{
                                                return(
                                                    <option value={item}>{item}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <select className="chatName"  onChange={(e)=>{setTargetStep(e.target.value)}}>
                                        <option value={""}>Cтатус интеграции</option>
                                        <option value={"in_process"}>ТЗ Принято</option>
                                        <option value={"waiting_for_payment"}>Ожидание оплаты</option>
                                        <option value={"payment_completed"}>Оплата проведена</option>
                                        <option value={"product_ordered"}>Товар заказан</option>
                                        <option value={"product_received"}>Товар получен</option>
                                        <option value={"post_posted"}>Пост размещен</option>
                                        <option value={"bloger_done"}>Блогер подтвердил выполнение интеграции</option>
                                        <option value={"w8_bloger_feedback"}>Ожидание отзыва от блогера</option>
                                        <option value={"done"}>Выполнено</option> 
                                    </select>
                                    </>
                                ):(
                                    <Link 
                                        to={`/account/`}
                                        style={{
                                            backgroundColor: "red",
                                            display: "block",
                                            textAlign: "center",
                                            marginBottom: 15,
                                            color: "white",
                                            borderRadius: 10,
                                            fontSize: 16,
                                            padding: "13px 10px"
                                        }}
                                    >
                                        Раздел Мои задания
                                    </Link>
                                )
                            }
                            <div className="chat-list">
                                {
                                    chat.chatList ? (
                                        chat.chatList.length ? (
                                            chat.chatList.map((item, index) => {
                                                if(
                                                    (item.integration.title != targetName && targetName != "") ||
                                                    (item.applicate.status != targetStep && targetStep != "")
                                                )
                                                    return false
                                                return(
                                                    <div className={`chat-list__item ${item.pk == chat.targetChat ? "active": ""}`} onClick={()=>{
                                                        if(!loadMsg){
                                                            setTargetChat(index)
                                                            //setLoad(true)
                                                            setLoadMsg(true)
                                                            item.blogerOpen = true
                                                            item.sellerOpen = true
                                                            chat.UpdateChatItem(auth.user.userToken, item.pk, item.applicate.status).then(response =>{
                                                                //setLoad(false) 
                                                                setChatMsh(chat.chatMsg)
                                                                setLoadMsg(false)
                                                            }).catch(error => {
                                                                alert("error")
                                                            })
                                                        }
                                                    }}>
                                                        {
                                                            auth.user.userType == "seller" ? (
                                                                <>                                                            
                                                                    <div className="chat-list__logo">
                                                                        <div style={{backgroundImage: `url('https://api.blogerito.ru/${item.applicate.bloger.image}')`}}></div>
                                                                    </div>
                                                                    <div className="chat-list__desc">
                                                                        <h3>{item.integration.title}</h3>
                                                                        {
                                                                            !item.sellerOpen ? (
                                                                                <p style={{fontWeight: "600", color: "red"}}><b>Есть новые сообщения</b></p>
                                                                            ):""
                                                                        }
                                                                        <p><b>Последнее сообщение:</b><br/> {item.status} {item.date}</p>
                                                                        <p><b>Блогер:</b> <Link to={`/catalog/${item.applicate.bloger.pk}/`}>{item.applicate.bloger.name}</Link></p>
                                                                        <p><b>Статус интеграции:</b><br/> {
                                                                            item.applicate.status == "w8_bloger" ? 'Ожидает подтверждения от блогера':
                                                                            item.applicate.status == "w8_seller" ? 'Ожидает подтверждения от рекламодателя':
                                                                            item.applicate.status == "cancelled_bloger" ? 'Отменено блогером':
                                                                            item.applicate.status == "cancelled_seller" ? 'Отменено рекламодателемм':
                                                                            item.applicate.status == "in_process" ? 'В процессе выполнения':
                                                                            item.applicate.status == "tk_accepted" ? "ТЗ Принято":
                                                                            item.applicate.status == "waiting_for_payment" ? "Ожидание оплаты":
                                                                            item.applicate.status == "payment_completed" ? "Оплата проведена":
                                                                            item.applicate.status == "product_ordered" ? "Товар заказан":
                                                                            item.applicate.status == "product_received" ? "Товар получен":
                                                                            item.applicate.status == "post_posted" ? "Пост размещен":
                                                                            item.applicate.status == "bloger_done" ? 'Блогер подтвердил выполнение интеграции':
                                                                            item.applicate.status == "w8_bloger_feedback" ? 'Ожидание отзыва от блогера':
                                                                            item.applicate.status == "done" ? 'Выполнено':""
                                                                        }</p>
                                                                    </div>
                                                                </>
                                                            ):(
                                                                <>
                                                                    <div className="chat-list__logo">
                                                                        <div style={{backgroundImage: `url('https://api.blogerito.ru/${item.integration.productImage}')`}}></div>
                                                                    </div>
                                                                    <div className="chat-list__desc">
                                                                        <h3>{item.integration.title}</h3>
                                                                        {
                                                                            !item.blogerOpen ? (
                                                                                <p style={{fontWeight: "600", color: "#3A0CA3"}}><b>Есть новые сообщения</b></p>
                                                                            ):""
                                                                        }
                                                                        <p>
                                                                            <b><Link to={`/account/`}>Мои задания</Link></b>
                                                                        </p>
                                                                        <p><b>Последнее сообщение:</b><br/> {item.status} {item.date}</p>
                                                                        
                                                                        <p><b>Рекламодатель:</b> {item.integration.seller.name}</p>
                                                                    </div>
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                )
                                            })
                                        ):(
                                            <p>Список пуст</p>
                                        )
                                    ):(
                                        <p>Список пуст</p>
                                    )
                                }
                            </div>
                        </div>
                        <div className="col-md-8"> 
                            <div className="integration-chat">
                                <ScrollToBottom mode={"bottom"} className="integration-chat__mspace">
                                    {
                                        loadMsg ? (
                                            <Loader />
                                        ):(
                                        <>
                                            {
                                                chat.targetChat != 0 ? (
                                                    <>
                                                        {
                                                            auth.user.userType == "bloger" ? (
                                                                <div className={`integration-chat__msg other`}>
                                                                    <div>
                                                                        <div className="integration-chat__text">Заявка в интеграцию успешно одобрена. Пожалуйста, посмотрите ТЗ в разделе <Link to="/account/">Мои задания!</Link></div>
                                                                        <div className="integration-chat__userinfo">
                                                                            Админ
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ):(
                                                                <div className={`integration-chat__msg other`}>
                                                                    <div>
                                                                        <div className="integration-chat__text">Заявка в интеграцию успешно одобрена</div>
                                                                        <div className="integration-chat__userinfo">
                                                                            Админ
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                ):""
                                            }
                                            {
                                                chatMsg ? (
                                                    chatMsg.length ? (
                                                        chatMsg.map((item: any, index) => {
                                                            return(
                                                                <div className={`integration-chat__msg ${auth.user.userType == "bloger" && item.status != "Блогер" ? "other": auth.user.userType == "seller" && item.status != "Рекламодатель" ? "other": ""}`} key={index}>
                                                                    <div>
                                                                        <div className="integration-chat__text" dangerouslySetInnerHTML={{__html: item.minidesc}}></div>
                                                                        {
                                                                            item.image != "/media/0" && item.image ? (
                                                                                <a className="dopimage" href={`https://api.blogerito.ru${item.image}`} target="_blank">
                                                                                    <img src={`https://api.blogerito.ru${item.image}`} />
                                                                                </a>
                                                                            ):""
                                                                        }
                                                                        <div className="integration-chat__userinfo">
                                                                            {item.status} <span>{item.date}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    ):""
                                                ):""
                                            }  
                                            {
                                                auth.user.userType == "bloger" && chat.targetStatus == "w8_bloger_feedback" ? (
                                                    <div className="integration-chat__conf">
                                                        <div>
                                                            <h3>Оставьте отзыв для завершения интеграции</h3>
                                                            <div>
                                                                <textarea 
                                                                    maxLength={510}
                                                                    placeholder="Отзыв"
                                                                    value={comSeller} 
                                                                    onChange={e => setComSeller(e.target.value)}                                                         
                                                                />
                                                                <label>
                                                                    Оценка:
                                                                    <span>
                                                                        <svg onClick={()=>{
                                                                            setStars(1)
                                                                        }} xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                                                            <path  fill={stars >= 1 ? "#F8C50F" : "rgb(170 170 170)"} d="M8.02447 0.463524C8.17415 0.00286853 8.82585 0.00287008 8.97553 0.463525L10.5206 5.21885C10.5876 5.42486 10.7795 5.56434 10.9962 5.56434H15.9962C16.4806 5.56434 16.6819 6.18415 16.2901 6.46885L12.245 9.4078C12.0697 9.53512 11.9964 9.7608 12.0633 9.96681L13.6084 14.7221C13.7581 15.1828 13.2309 15.5659 12.839 15.2812L8.79389 12.3422C8.61865 12.2149 8.38135 12.2149 8.20611 12.3422L4.16099 15.2812C3.76913 15.5659 3.24189 15.1828 3.39157 14.7221L4.93667 9.96681C5.0036 9.7608 4.93027 9.53512 4.75503 9.4078L0.709911 6.46885C0.318054 6.18415 0.519443 5.56434 1.0038 5.56434H6.00385C6.22046 5.56434 6.41244 5.42486 6.47937 5.21885L8.02447 0.463524Z"/>
                                                                        </svg>
                                                                        <svg onClick={()=>{
                                                                            setStars(2)
                                                                        }} xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                                                            <path  fill={stars >= 2 ? "#F8C50F" : "rgb(170 170 170)"} d="M8.02447 0.463524C8.17415 0.00286853 8.82585 0.00287008 8.97553 0.463525L10.5206 5.21885C10.5876 5.42486 10.7795 5.56434 10.9962 5.56434H15.9962C16.4806 5.56434 16.6819 6.18415 16.2901 6.46885L12.245 9.4078C12.0697 9.53512 11.9964 9.7608 12.0633 9.96681L13.6084 14.7221C13.7581 15.1828 13.2309 15.5659 12.839 15.2812L8.79389 12.3422C8.61865 12.2149 8.38135 12.2149 8.20611 12.3422L4.16099 15.2812C3.76913 15.5659 3.24189 15.1828 3.39157 14.7221L4.93667 9.96681C5.0036 9.7608 4.93027 9.53512 4.75503 9.4078L0.709911 6.46885C0.318054 6.18415 0.519443 5.56434 1.0038 5.56434H6.00385C6.22046 5.56434 6.41244 5.42486 6.47937 5.21885L8.02447 0.463524Z"/>
                                                                        </svg>
                                                                        <svg onClick={()=>{
                                                                            setStars(3)
                                                                        }} xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                                                            <path  fill={stars >= 3 ? "#F8C50F" : "rgb(170 170 170)"} d="M8.02447 0.463524C8.17415 0.00286853 8.82585 0.00287008 8.97553 0.463525L10.5206 5.21885C10.5876 5.42486 10.7795 5.56434 10.9962 5.56434H15.9962C16.4806 5.56434 16.6819 6.18415 16.2901 6.46885L12.245 9.4078C12.0697 9.53512 11.9964 9.7608 12.0633 9.96681L13.6084 14.7221C13.7581 15.1828 13.2309 15.5659 12.839 15.2812L8.79389 12.3422C8.61865 12.2149 8.38135 12.2149 8.20611 12.3422L4.16099 15.2812C3.76913 15.5659 3.24189 15.1828 3.39157 14.7221L4.93667 9.96681C5.0036 9.7608 4.93027 9.53512 4.75503 9.4078L0.709911 6.46885C0.318054 6.18415 0.519443 5.56434 1.0038 5.56434H6.00385C6.22046 5.56434 6.41244 5.42486 6.47937 5.21885L8.02447 0.463524Z"/>
                                                                        </svg>
                                                                        <svg onClick={()=>{
                                                                            setStars(4)
                                                                        }} xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                                                            <path  fill={stars >= 4 ? "#F8C50F" : "rgb(170 170 170)"} d="M8.02447 0.463524C8.17415 0.00286853 8.82585 0.00287008 8.97553 0.463525L10.5206 5.21885C10.5876 5.42486 10.7795 5.56434 10.9962 5.56434H15.9962C16.4806 5.56434 16.6819 6.18415 16.2901 6.46885L12.245 9.4078C12.0697 9.53512 11.9964 9.7608 12.0633 9.96681L13.6084 14.7221C13.7581 15.1828 13.2309 15.5659 12.839 15.2812L8.79389 12.3422C8.61865 12.2149 8.38135 12.2149 8.20611 12.3422L4.16099 15.2812C3.76913 15.5659 3.24189 15.1828 3.39157 14.7221L4.93667 9.96681C5.0036 9.7608 4.93027 9.53512 4.75503 9.4078L0.709911 6.46885C0.318054 6.18415 0.519443 5.56434 1.0038 5.56434H6.00385C6.22046 5.56434 6.41244 5.42486 6.47937 5.21885L8.02447 0.463524Z"/>
                                                                        </svg>
                                                                        <svg onClick={()=>{
                                                                            setStars(5)
                                                                        }} xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                                                            <path  fill={stars >= 5 ? "#F8C50F" : "rgb(170 170 170)"} d="M8.02447 0.463524C8.17415 0.00286853 8.82585 0.00287008 8.97553 0.463525L10.5206 5.21885C10.5876 5.42486 10.7795 5.56434 10.9962 5.56434H15.9962C16.4806 5.56434 16.6819 6.18415 16.2901 6.46885L12.245 9.4078C12.0697 9.53512 11.9964 9.7608 12.0633 9.96681L13.6084 14.7221C13.7581 15.1828 13.2309 15.5659 12.839 15.2812L8.79389 12.3422C8.61865 12.2149 8.38135 12.2149 8.20611 12.3422L4.16099 15.2812C3.76913 15.5659 3.24189 15.1828 3.39157 14.7221L4.93667 9.96681C5.0036 9.7608 4.93027 9.53512 4.75503 9.4078L0.709911 6.46885C0.318054 6.18415 0.519443 5.56434 1.0038 5.56434H6.00385C6.22046 5.56434 6.41244 5.42486 6.47937 5.21885L8.02447 0.463524Z"/>
                                                                        </svg>
                                                                    </span>
                                                                </label>
                                                                <div className="button-chatrev">
                                                                    <button onClick={()=>{ 
                                                                        if(comSeller == ""){
                                                                            setPopupType("Укажите отзыв для закрытия заказа")
                                                                            return false
                                                                        }
                                                                        setLoad(true)
                                                                        chat.CommentOrder(auth.user.userToken, comSeller, stars).then(function(response){
                                                                            chat.targetChat = 0
                                                                            chat.targetStatus = ""
                                                                            setChat(chat)
                                                                            setLoad(false)        
                                                                            window.location.reload();                                            
                                                                        }).catch(function(error){
                                                                            alert("error")
                                                                        })
                                                                    }}>Подтвердить</button> 
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ):""
                                            } 
                                            {
                                                auth.user.userType == "seller" && chat.targetStatus == "bloger_done" ? (
                                                    <div className="integration-chat__conf">
                                                        <div>
                                                            <h3>Блогер подтвердил выполнение интеграции</h3>
                                                            <div>
                                                                <textarea 
                                                                    maxLength={510}
                                                                    placeholder="Отзыв / Причина отправления на доработку"
                                                                    value={comSeller} 
                                                                    onChange={e => setComSeller(e.target.value)}                                                         
                                                                />
                                                                <label>
                                                                    Оценка:
                                                                    <span>
                                                                        <svg onClick={()=>{
                                                                            setStars(1)
                                                                        }} xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                                                            <path  fill={stars >= 1 ? "#F8C50F" : "rgb(170 170 170)"} d="M8.02447 0.463524C8.17415 0.00286853 8.82585 0.00287008 8.97553 0.463525L10.5206 5.21885C10.5876 5.42486 10.7795 5.56434 10.9962 5.56434H15.9962C16.4806 5.56434 16.6819 6.18415 16.2901 6.46885L12.245 9.4078C12.0697 9.53512 11.9964 9.7608 12.0633 9.96681L13.6084 14.7221C13.7581 15.1828 13.2309 15.5659 12.839 15.2812L8.79389 12.3422C8.61865 12.2149 8.38135 12.2149 8.20611 12.3422L4.16099 15.2812C3.76913 15.5659 3.24189 15.1828 3.39157 14.7221L4.93667 9.96681C5.0036 9.7608 4.93027 9.53512 4.75503 9.4078L0.709911 6.46885C0.318054 6.18415 0.519443 5.56434 1.0038 5.56434H6.00385C6.22046 5.56434 6.41244 5.42486 6.47937 5.21885L8.02447 0.463524Z"/>
                                                                        </svg>
                                                                        <svg onClick={()=>{
                                                                            setStars(2)
                                                                        }} xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                                                            <path  fill={stars >= 2 ? "#F8C50F" : "rgb(170 170 170)"} d="M8.02447 0.463524C8.17415 0.00286853 8.82585 0.00287008 8.97553 0.463525L10.5206 5.21885C10.5876 5.42486 10.7795 5.56434 10.9962 5.56434H15.9962C16.4806 5.56434 16.6819 6.18415 16.2901 6.46885L12.245 9.4078C12.0697 9.53512 11.9964 9.7608 12.0633 9.96681L13.6084 14.7221C13.7581 15.1828 13.2309 15.5659 12.839 15.2812L8.79389 12.3422C8.61865 12.2149 8.38135 12.2149 8.20611 12.3422L4.16099 15.2812C3.76913 15.5659 3.24189 15.1828 3.39157 14.7221L4.93667 9.96681C5.0036 9.7608 4.93027 9.53512 4.75503 9.4078L0.709911 6.46885C0.318054 6.18415 0.519443 5.56434 1.0038 5.56434H6.00385C6.22046 5.56434 6.41244 5.42486 6.47937 5.21885L8.02447 0.463524Z"/>
                                                                        </svg>
                                                                        <svg onClick={()=>{
                                                                            setStars(3)
                                                                        }} xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                                                            <path  fill={stars >= 3 ? "#F8C50F" : "rgb(170 170 170)"} d="M8.02447 0.463524C8.17415 0.00286853 8.82585 0.00287008 8.97553 0.463525L10.5206 5.21885C10.5876 5.42486 10.7795 5.56434 10.9962 5.56434H15.9962C16.4806 5.56434 16.6819 6.18415 16.2901 6.46885L12.245 9.4078C12.0697 9.53512 11.9964 9.7608 12.0633 9.96681L13.6084 14.7221C13.7581 15.1828 13.2309 15.5659 12.839 15.2812L8.79389 12.3422C8.61865 12.2149 8.38135 12.2149 8.20611 12.3422L4.16099 15.2812C3.76913 15.5659 3.24189 15.1828 3.39157 14.7221L4.93667 9.96681C5.0036 9.7608 4.93027 9.53512 4.75503 9.4078L0.709911 6.46885C0.318054 6.18415 0.519443 5.56434 1.0038 5.56434H6.00385C6.22046 5.56434 6.41244 5.42486 6.47937 5.21885L8.02447 0.463524Z"/>
                                                                        </svg>
                                                                        <svg onClick={()=>{
                                                                            setStars(4)
                                                                        }} xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                                                            <path  fill={stars >= 4 ? "#F8C50F" : "rgb(170 170 170)"} d="M8.02447 0.463524C8.17415 0.00286853 8.82585 0.00287008 8.97553 0.463525L10.5206 5.21885C10.5876 5.42486 10.7795 5.56434 10.9962 5.56434H15.9962C16.4806 5.56434 16.6819 6.18415 16.2901 6.46885L12.245 9.4078C12.0697 9.53512 11.9964 9.7608 12.0633 9.96681L13.6084 14.7221C13.7581 15.1828 13.2309 15.5659 12.839 15.2812L8.79389 12.3422C8.61865 12.2149 8.38135 12.2149 8.20611 12.3422L4.16099 15.2812C3.76913 15.5659 3.24189 15.1828 3.39157 14.7221L4.93667 9.96681C5.0036 9.7608 4.93027 9.53512 4.75503 9.4078L0.709911 6.46885C0.318054 6.18415 0.519443 5.56434 1.0038 5.56434H6.00385C6.22046 5.56434 6.41244 5.42486 6.47937 5.21885L8.02447 0.463524Z"/>
                                                                        </svg>
                                                                        <svg onClick={()=>{
                                                                            setStars(5)
                                                                        }} xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                                                            <path  fill={stars >= 5 ? "#F8C50F" : "rgb(170 170 170)"} d="M8.02447 0.463524C8.17415 0.00286853 8.82585 0.00287008 8.97553 0.463525L10.5206 5.21885C10.5876 5.42486 10.7795 5.56434 10.9962 5.56434H15.9962C16.4806 5.56434 16.6819 6.18415 16.2901 6.46885L12.245 9.4078C12.0697 9.53512 11.9964 9.7608 12.0633 9.96681L13.6084 14.7221C13.7581 15.1828 13.2309 15.5659 12.839 15.2812L8.79389 12.3422C8.61865 12.2149 8.38135 12.2149 8.20611 12.3422L4.16099 15.2812C3.76913 15.5659 3.24189 15.1828 3.39157 14.7221L4.93667 9.96681C5.0036 9.7608 4.93027 9.53512 4.75503 9.4078L0.709911 6.46885C0.318054 6.18415 0.519443 5.56434 1.0038 5.56434H6.00385C6.22046 5.56434 6.41244 5.42486 6.47937 5.21885L8.02447 0.463524Z"/>
                                                                        </svg>
                                                                    </span>
                                                                </label>
                                                                <div className="button-chatrev">
                                                                    <button onClick={()=>{ 
                                                                        if(comSeller == ""){
                                                                            setPopupType("Укажите отзыв для закрытия заказа")
                                                                            return false
                                                                        }
                                                                        setLoad(true)
                                                                        chat.CommentOrder(auth.user.userToken, comSeller, stars).then(function(response){
                                                                            chat.targetChat = 0
                                                                            chat.targetStatus = ""
                                                                            setChat(chat)
                                                                            setLoad(false)        
                                                                            window.location.reload();                                            
                                                                        }).catch(function(error){
                                                                            alert("error")
                                                                        })
                                                                    }}>Подтвердить</button>
                                                                    <button onClick={()=>{
                                                                        setLoad(true)
                                                                         
                                                                        chat.CancelCompletion(auth.user.userToken, comSeller).then(function(response){
                                                                            chat.UpdateChatItem(auth.user.userToken, chat.targetChat, chat.targetStatus).then(response =>{
                                                                                console.log("update") 
                                                                                setUpdateTest(!updateTest)
                                                                                setChat(chat)
                                                                                setLoad(false)
                                                                                window.location.reload();
                                                                            }).catch(error => {
                                                                                console.log("error")
                                                                            })
                                                                        }).catch(function(error){
                                                                            alert("error")
                                                                        })
                                                                    }}>Отправить на доработку</button>
                                                                    <button style={{backgroundColor: "#ff4747"}} onClick={()=>{
                                                                        setShowPopup(true)                                                        
                                                                    }}>Отменить заказ</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ):""
                                            }
                                            </>
                                        )
                                    } 
                                </ScrollToBottom>








                                {
                                    chat.targetStatus == "cancelled_bloger" ||
                                    chat.targetStatus == "cancelled_seller" ||
                                    chat.targetStatus == "w8_bloger_feedback" ||
                                    chat.targetStatus == "done" ? (
                                        <div style={{
                                            textAlign: "right"
                                        }}>
                                            <button 
                                                onClick={()=>{
                                                    chat.ChatDelete(auth.user.userToken).then(function(response){
                                                        window.location.reload();
                                                    }).catch(function(error){
                                                        window.location.reload();
                                                    })
                                                }}
                                                style={{
                                                    marginBottom: "-30px",
                                                    marginTop: "15px",
                                                    backgroundColor: "#C3151F",
                                                }}
                                            >Удалить чат</button>
                                        </div>
                                    ):""
                                }

                                <form onSubmit={handleSubmit}>
                                    {
                                        chat.targetStatus != "" && 
                                        chat.targetStatus != "w8_bloger" && 
                                        chat.targetStatus != "w8_seller" && 
                                        chat.targetStatus != "cancelled_bloger" &&
                                        chat.targetStatus != "cancelled_seller" &&
                                        chat.targetStatus != "w8_bloger_feedback" &&
                                        chat.targetStatus != "done" ? (
                                            <>
                                                <div>
                                                    {
                                                        templatesMsg.list.length ? (
                                                            <select 
                                                                onChange={(e)=>{
                                                                    if(e.target.value != ""){
                                                                        setMsg(e.target.value)
                                                                    }
                                                                }}
                                                                style={{
                                                                    width: "100%",
                                                                    marginBottom: 10,
                                                                    padding: 10,
                                                                    borderRadius: 10
                                                                }}
                                                            >
                                                                <option value={""}>Шаблоны сообщений</option>
                                                                {
                                                                    templatesMsg.list.length ? (
                                                                        templatesMsg.list.map((item, index) => {
                                                                            return(
                                                                                <option value={item.minidesc}>{item.title}</option>
                                                                            )
                                                                        })
                                                                    ):""
                                                                }
                                                            </select>
                                                        ):""
                                                    }
                                                </div>
                                                <textarea maxLength={510} placeholder="Сообщение"  name="msg" required={msgFile == "" ? true: false} value={msg}  onChange={e => setMsg(e.target.value)} />                                            

                                                {
                                                    msgFile == "" ? (
                                                        <label style={{cursor: "pointer"}}>
                                                            <div className="dop-file project" style={{marginTop: "10px", marginBottom: "10px"}}>
                                                                Прикрепить фото
                                                                <input 
                                                                    type="file"    
                                                                    name="file" 
                                                                    onChange={(e: any)=>{
                                                                        setFileName(e.target.files[0].name)
                                                                        setMsgFile(e.target.files[0])
                                                                    }} 
                                                                    accept="image/*"
                                                                />
                                                            </div>
                                                        </label>
                                                    ):(
                                                        <div>
                                                            <div className="dop-file project" style={{marginTop: "10px", marginBottom: "10px"}}>
                                                                {fileName} <span 
                                                                onClick={()=>{
                                                                    setMsgFile("")
                                                                    setFileName("")
                                                                }}
                                                                style={{
                                                                    color: "red",
                                                                    display: "inline-block",
                                                                    width: "auto",
                                                                    paddingLeft: "10px",
                                                                    cursor: "pointer"
                                                                }}>X</span>
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                                <button>Отправить</button>
                                            </>
                                        ):""
                                    }
                                    {                                
                                        chat.targetStatus != "w8_bloger" && 
                                        chat.targetStatus != "w8_seller" && 
                                        chat.targetStatus != "cancelled_bloger" && 
                                        chat.targetStatus != "cancelled_seller" && 
                                        chat.targetStatus != "w8_bloger_feedback" && 
                                        chat.targetStatus != "done" && 
                                        auth.user.userType == "seller" ? (
                                            <span style={{color: "white"}}>
                                                <span onClick={()=>{
                                                    setShowPopup(true)                                                        
                                                }}>Отменить заказ</span>
                                            </span>
                                        ):""
                                    } 
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section> 

            {
                showPopup ? (
                    <div className="popupChat">
                        <div>
                            {                                
                                auth.user.userType == "seller" ? (
                                    <>
                                        <h3>Вы уверены? Интеграция с данным блогером будет больше недоступна</h3>
                                        <form onSubmit={()=>{
                                            setLoad(true)
                                            chat.CancelOrder(auth.user.userToken, conselReason).then(function(response){
                                                chat.targetChat = 0
                                                setShowPopup(false)
                                                setChat(chat)
                                                setLoad(false)
                                                window.location.reload();
                                            }).catch(function(error){
                                                chat.targetChat = 0
                                                setShowPopup(false)
                                                setChat(chat)
                                                setLoad(false)
                                                alert("error")
                                            })
                                        }}>
                                            <textarea required value={conselReason} onChange={(e)=>{setConselReason(e.target.value)}} placeholder="Причина отмены" className="cancel-text" />
                                            <button style={{backgroundColor: "#3A0CA3"}}>Отменить заказ</button>
                                            <button onClick={()=>{
                                                setShowPopup(false)
                                                return false
                                            }}>Продолжить</button>
                                        </form>
                                    </>
                                ):""
                            }
                        </div>
                    </div>
                ):""
            }
            
            { 
                popupType != "" ? (                                 
                    <div className="popup-msg">
                        <div className="popup-msg__body">
                            <h3>{popupType}</h3>
                            <div className="popup-msg__link" onClick={()=>{ 
                                setPopupType("") 
                            }}>
                                Продолжить
                            </div>
                        </div>
                    </div>
                ):""
            }     
        </>
    )
}
