import React, { useContext, useState } from "react"
import { GlobalContext } from "../../context/GlobalContext" 
import { BlogerVideoPage } from "./BlogerVideoPage/BlogerVideoPage"
import { SellerVideoPage } from "./SellerVideoPage/SellerVideoPage"

export const VideoPage = () => {  
    const auth = useContext(GlobalContext)  

    return( 
        <>
            {
                auth.user.userType == "bloger" ? (
                    <BlogerVideoPage />
                ):(
                    <SellerVideoPage />
                )
            } 
        </>
    )
}