import { Helmet } from "react-helmet"

export const AboutPage = () => {  
    return( 
        <>
            <Helmet>
                <title>Имеем уникальную экспертизу, так как сами являемся продавцами на Вайлдберис</title>
                <meta
                    name="description"
                    content='Мы получили большой опыт работы с соцсетями и успели посотрудничать с блогерами разного уровня – от наноблогеров до топ-блогеров. Весь этот опыт и экспертиза легли в основу сервиса.'
                />
            </Helmet>
            <section>
                <div className="content">
                    <h1 className="title">О нас</h1>
                    <div className="row">
                        <div className="col-md-6">
                            <p>Мы – компания Wb-Expert. Занимаемся полным комплексом работ для помощи продавцам на маркетплейсах:</p>

                            <ul>
                                <li>Фулфилмент</li>
                                <li>Доставка товаров из Китая</li>
                                <li>Маркетинговое агентство</li>
                                <li>Веб-сервисы для работы с маркетплейсами</li>
                            </ul>
                                
                            <p>Имеем уникальную экспертизу, так как сами являемся успешными продавцами на Вайлдберис, Озон и Яндекс Маркет.</p>
                            <p>Оборот наших собственных брендов за 2022 г. составил 73 000 000 рублей.</p>
                            <p>Мы получили большой опыт работы с соцсетями и успели посотрудничать с блогерами разного уровня – от наноблогеров до топ-блогеров. Весь этот опыт и экспертиза легли в основу сервиса.</p>
                        </div>
                        <div className="col-md-6">
                            <div className="blog-list__wrapper"></div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}