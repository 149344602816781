import Slider from "react-slick";
import { SampleNextArrow } from './Arrows/SampleNextArrow';
import { SamplePrevArrow } from './Arrows/SamplePrevArrow';

export const BlogerWork = (props: any) => {
    console.log(props.images)
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplaySpeed: 2000,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 760,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    return(
        <>
            <div className="bloger-works__wrapper">
                <div className="bloger-works__images">
                    <div className="image">
                        <div style={{backgroundImage: `url('${props.image}')`}}></div>
                    </div>
                    <div className="text">
                        <div className="grid">
                            <div>
                                <h3>{props.name}</h3>
                                <p>{props.uDesc}</p>
                            </div>
                        </div>
                    </div>
                </div>
                {props.bodyText}

                <div className='bloger-works__slider'>
                    <Slider {...settings}>
                        {
                            props.images ? (
                                props.images.map((item: any, index: number) => {
                                    return(
                                        <div>
                                            <div className='bloger-works__item'>
                                                <div style={{
                                                    backgroundImage: `url('${item}')`
                                                }}></div>
                                            </div>
                                        </div>
                                    )
                                })
                            ):""
                        }  
                    </Slider>
                </div>
            </div>
        </>
    )
}