import { Routes, Route } from "react-router-dom";
import { Layout } from "./layouts/layout" 
import {User} from './classes'
import { useMyRoutes } from "./routes"
import { GlobalContext } from "./context/GlobalContext"
import { Header } from './component/Header';
import { Footer } from './component/Footer'; 
import { Helmet } from "react-helmet";

function App() { 
    const routes = useMyRoutes() 

  return (
    <GlobalContext.Provider value={{
        user: new User
    }}> 

        <Header /> 
        <Routes>
            <Route element={<Layout />}>{routes}</Route>
        </Routes> 
        <Footer />
    </GlobalContext.Provider>
  );
}

export default App;
