import React, { useContext, useEffect, useState } from "react"
import icon1 from "../image/icon.svg"
import icon2 from "../image/icon-2.svg"
import icon3 from "../image/icon-3.svg"
import icon4 from "../image/icon-4.svg"
import icon5 from "../image/icon-5.svg"
import icon6 from "../image/icon-6.svg" 
import bannerHuman from "../image/banner_human.png"
import greenscrine from "../image/greenscrine.jpeg"
import whyHuman from "../image/why_human.png"
import { GlobalContext } from "../context/GlobalContext" 
import { Link } from "react-router-dom"; 

import bannerImgO12 from "../image/banner-img_0012.jpg" 

import imgInst01 from "../image/img-inst_01.jpg" 
import imgInst02 from "../image/img-inst_02.jpg" 
import imgInst03 from "../image/img-inst_03.jpg" 
import imgInst04 from "../image/img-inst_04.jpg" 
import imgInst05 from "../image/img-inst_05.jpg" 
import imgInst06 from "../image/img-inst_06.jpg" 
import imgInst07 from "../image/img-inst_07.jpg" 
import imgInst08 from "../image/img-inst_08.jpg" 
import imgInst09 from "../image/img-inst_09.jpg" 
import fiveLogo from "../image/3.png" 
import imgInst12 from "../image/img-inst_12.jpg" 
import imgInst13 from "../image/img-inst_13.jpg" 
import imgInst14 from "../image/img-inst_14.jpg" 
import imgInst15 from "../image/img-inst_15.jpg" 
import imgInst16 from "../image/img-inst_16.jpg" 
import greenscrine1 from "../image/greenscrine1.png"
import greenscrine2 from "../image/greenscrine2.png"
import greenscrine3 from "../image/greenscrine3.png"
import greenscrine4 from "../image/greenscrine4.jpeg"
import greenscrine5 from "../image/greenscrine5.png"
import caseImg1 from "../image/case-img__01.jpg"

const howWorksList = [
    {
        "title": "Создайте интеграцию",
        "text": "Укажите формат интеграции, социальную сеть для рекламы, ссылку на товар",
        "img": greenscrine1
    },
    {
        "title": "Поиск блогеров",
        "text": "Используйте умный поиск по каталогу блогеров либо получайте входящие заявки от исполнителей",
        "img": greenscrine2
    },
    {
        "title": "Пополнение баланса",
        "text": "Оплатите один из тарифов для получения полного доступа к нашему сервису",
        "img": greenscrine3
    },
    {
        "title": "Согласование и публикация",
        "text": "Обсудите в чате ваши пожелания к размещению и формату, сроки и требования к публикации рекламного поста",
        "img": greenscrine4
    },
    {
        "title": "Отчет блогера",
        "text": "После размещения получите отчет от блогера, поблагодарите друг друга и обменяйтесь отзывами",
        "img": greenscrine5
    }
]

export const FrontPage = () => {  
    const auth = useContext(GlobalContext)  

    const [targetItem, setTargetItem] = useState(0) 

/*
    useEffect(()=>{
        localStorage.setItem("userStorage", JSON.stringify({ 
            pk: 0,
            isAuthenticated: true,
            userToken: "091c01067d24eed5d81d73f9ae046b5e9b9c720c",
            userName: "test login",
            image: `https://api.blogerito.ru/media/0a7187c9b5aa4b3c9a84c39c7fd36fc0.webp`,
            userType: "seller",
        }))
    },[])
*/

    const HowWorkItem = (item: number)=>{
        setTargetItem(item) 
    }

    return( 
        <>
            <section className="banner">
            <div className="content">
                <div className="row">
                    <div className="col-md-8">
                        <div className="banner__content"> 
                            <h2>ДЛЯ СЕЛЛЕРОВ НА МАРКЕТПЛЕЙСАХ</h2>
                            <h1 style={{
                                marginBottom: 0
                            }}>
                                Продвигайте свои товары в ТОП с помощью бартера с микроблогерами
                            </h1>
                            <p style={{
                                marginTop: "15px",
                                marginBottom: "30px",
                                fontSize: "17px"
                            }}>
                                Легальная замена самовыкупам, отзывы и выкупы без штрафов
                            </p>
                            <ul className="banner_soc">
                                <li>
                                    <img src={icon1} />
                                </li>
                                <li>
                                    <img src={icon2} />
                                </li>
                                <li>
                                    <img src={icon3} />
                                </li>
                                <li>
                                    <img src={icon4} />
                                </li>
                                <li>
                                    <img src={icon5} />
                                </li>
                                <li>
                                    <img src={icon6} />
                                </li>
                            </ul>

                            <div className="banner__btns">
                                <Link to="/login/">Я продавец</Link>
                                <Link to="/bloger/" style={{backgroundColor: "#9B51E0"}}>Я блогер</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="grid center">
                            <img className="banner_human" src={bannerHuman} />
                        </div>
                    </div>
                </div>
                <div className="banner__mini">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="banner__mini-wrapper">
                                <div className="banner__mini-imgs ">
                                    <div className="">
                                        <div className="bammer-user__img" style={{backgroundImage: "url('"+imgInst12+"')"}}></div>
                                        <div className="bammer-user__img" style={{backgroundImage: "url('"+imgInst13+"')"}}></div>
                                        <div className="bammer-user__img" style={{backgroundImage: "url('"+imgInst14+"')"}}></div>
                                        <div className="bammer-user__img" style={{backgroundImage: "url('"+imgInst15+"')"}}></div>
                                        <div className="bammer-user__img" style={{backgroundImage: "url('"+imgInst16+"')"}}></div>
                                    </div>
                                </div>
                                <div className="banner__mini-text">
                                    <div className="grid">
                                        <span>
                                            900+ блогеров<br/>
                                            на площадке
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="banner__mini-wrapper wrapper-singe__img">
                                <div className="banner__mini-imgs ">
                                    <div className="">
                                        <div className="bammer-user__img" style={{backgroundImage: "url('"+bannerImgO12+"')"}}></div>
                                    </div>
                                </div>
                                <div className="banner__mini-text">
                                    <div className="grid">
                                        <span>
                                            200+ размещений<br/>
                                            ежедневно
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="banner__mini-wrapper wrapper-singe__img">
                                <div className="banner__mini-imgs ">
                                    <div className="">
                                        <div className="bammer-user__img" style={{backgroundImage: "url('"+fiveLogo+"')"}}></div>
                                    </div>
                                </div>
                                <div className="banner__mini-text">
                                    <div className="grid">
                                        <span>
                                            5 минут до вашей<br/>
                                            первой интеграции
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="how-work">
            <div className="content">

                <div style={{textAlign: "center", marginBottom: "50px", marginTop: "-40px"}}>
                    <iframe style={{border: "none", maxWidth: "100%"}} width="560" height="300" src="https://rutube.ru/play/embed/458a788f914f98a396784ff878ccda60/"></iframe>
                </div>
                <h2 className="title">Как работает сервис ?</h2>
                <div className="how-work__swiper">
                    <div className="row">
                        <div className="col-sm-five">
                            <div className={`how-work__swiper-item ${targetItem == 0 ? "active":""}`} onClick={()=>{
                                HowWorkItem(0)
                            }}>
                                <span>Создайте<br/>интеграцию</span>
                            </div>
                        </div>
                        <div className="col-sm-five">
                            <div className={`how-work__swiper-item ${targetItem == 1 ? "active":""}`} onClick={()=>{
                                HowWorkItem(1)
                            }}>
                                <span>Поиск<br/>блогеров</span>
                            </div>
                        </div>
                        <div className="col-sm-five">
                            <div className={`how-work__swiper-item ${targetItem == 2 ? "active":""}`} onClick={()=>{
                                HowWorkItem(2)
                            }}>
                                <span>Пополнение<br/>баланса</span>
                            </div>
                        </div>
                        <div className="col-sm-five">
                            <div className={`how-work__swiper-item ${targetItem == 3 ? "active":""}`} onClick={()=>{
                                HowWorkItem(3)
                            }}>
                                <span>Согласование<br/>и публикация</span>
                            </div>
                        </div>
                        <div className="col-sm-five">
                            <div className={`how-work__swiper-item ${targetItem == 4 ? "active":""}`} onClick={()=>{
                                HowWorkItem(4)
                            }}>
                                <span>Отчет<br/>блогера</span>
                            </div>
                        </div> 
                    </div>
                </div>

                <div className="how-work__content">
                    <div className="how-work__content-item">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="grid">
                                    <div>
                                        <h3>{howWorksList[targetItem].title}</h3>
                                        <p>{howWorksList[targetItem].text}</p>
                                        <Link to="/login/">Попробовать бесплатно</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="how-work__content-slide" style={{backgroundImage: "url('"+howWorksList[targetItem].img+"')"}}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="how-work">
            <div className="content">
                <h2 className="title">Тарифы</h2>
                <div className="row">
                    <div className="col-md-6 col-lg-3">
                        <div className="how-work__wrapper">
                            <div className="grid">
                                <h3>Тестовый<br/>доступ</h3>
                            </div>
                            <div className="how-work__price1">
                                <span>1</span><br/>
                                кол-во интеграций в месяц
                            </div>
                            
                            <div className="how-work__price1">
                                <span>0</span><br/>
                                руб
                            </div>
                            <Link to="/login/">Подключить</Link>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <div className="how-work__wrapper">
                            <div className="grid">
                                <h3>Лайт</h3>
                            </div>
                            <div className="how-work__price1">
                                <span>30</span><br/>
                                кол-во интеграций в месяц
                            </div>
                            
                            <div className="how-work__price1">
                                <span>5 000</span><br/>
                                руб
                            </div>
                            <Link to="/login/">Подключить</Link>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <div className="how-work__wrapper active">
                            <div className="how-work__rec">Рекомендуем</div>
                            <div className="grid">
                                <h3>Стандарт</h3>
                            </div>
                            <div className="how-work__price1">
                                <span>60</span><br/>
                                кол-во интеграций в месяц
                            </div>
                            
                            <div className="how-work__price1">
                                <span>8 000</span><br/>
                                руб
                            </div>
                            <Link to="/login/">Подключить</Link>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <div className="how-work__wrapper">
                            <div className="grid">
                                <h3>Премиум</h3>
                            </div>
                            <div className="how-work__price1">
                                <span>100</span><br/>
                                кол-во интеграций в месяц
                            </div>
                            
                            <div className="how-work__price1">
                                <span>12 000</span><br/>
                                руб
                            </div>
                            <Link to="/login/">Подключить</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
 
        <section className="start-work">
            <div className="content">
                <div className="start-work__wrapper">
                    <h2>Начните работу с блогерами</h2>
                    <div className="row">
                        <div className="col-md-6">
                            <h3>
                                ПЕРВАЯ ИНТЕГРАЦИЯ БЕСПЛАТНО,<br/>
                                А ЗАТЕМ 5 000 Р ЗА ДОСТУП НА 30 ДНЕЙ
                            </h3>
                            <p>Удобный выбор блогеров по десяткам параметров</p>
                            <p>Подходит для любого бизнеса</p>
                            <p>Высокая вовлеченность аудитории</p>
                            <p>Постоянная поддержка при работе с сервисом</p>
                        </div>
                        <div className="col-md-6 right"> 
                            <Link to="/login/">Попробовать бесплатно</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
 
        <section className="why-blogerito">
            <div className="content">
                <h3>ПОЧЕМУ BLOGERITO?</h3>
                <h2>Преимущества<br/>платформы</h2>
                <div className="row why-blogerito__list">
                    <div className="col-lg-8">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="why-blogerito__item">
                                    <div className="why-blogerito__image-wrapper">
                                        <div className="why-blogerito__image" style={{backgroundColor: "#4361EE"}}></div>
                                        <div className="why-blogerito__title">
                                            <div className="grid">
                                                <h4>Простота работы</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <p>Чтобы запустить первую интеграцию вам понадобится всего 5 минут</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="why-blogerito__item">
                                    <div className="why-blogerito__image-wrapper">
                                        <div className="why-blogerito__image" style={{backgroundColor: "#4361EE"}}></div>
                                        <div className="why-blogerito__title">
                                            <div className="grid">
                                                <h4>Автоматизация</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <p>Возможность отправки вашего предложения сразу десяткам блогеров</p>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="why-blogerito__item">
                                    <div className="why-blogerito__image-wrapper">
                                        <div className="why-blogerito__image" style={{backgroundColor: "#4361EE"}}></div>
                                        <div className="why-blogerito__title">
                                            <div className="grid">
                                                <h4>Нет риска получить штраф от маркетплейса</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <p>Абсолютно легальная белая альтернатива “самовыкупам”</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="why-blogerito__item">
                                    <div className="why-blogerito__image-wrapper">
                                        <div className="why-blogerito__image" style={{backgroundColor: "#4361EE"}}></div>
                                        <div className="why-blogerito__title">
                                            <div className="grid">
                                                <h4>Уникальные рекламные форматы</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <p>Механика работы с блогерами именно под маркетплейсы</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <img src={whyHuman} className="why-human" />
                    </div>
                </div>
            </div>
        </section>

        <section className="ind-blogers">
            <div className="content">
                <h3>ВЫБИРАЙТЕ ПРОВЕРЕННЫХ БЛОГЕРОВ</h3>
                <h2>900+ блогеров по<br/>всей России</h2>

                <p className="ind-blogers__desc">Доступные фильтры для выбора блогера: количество подписчиков, рейтинг на платформе, количество отзывов, социальная сеть, тематика блога (бьюти, бизнес, еда, спорт, юмор, дети и многое другое.)</p>
                <div className="ind-blogers__list row">
                    <div className="ind-blogers__item col">
                        <Link to="https://www.instagram.com/solimperchim/?igshid=MzRlODBiNWFlZA%3D%3D" target="_blank">
                            <div className="ind-blogers__img" style={{backgroundImage: "url('"+imgInst01+"')"}}></div>
                            <p> 
                                85k<br/>
                                подписчиков
                            </p>
                        </Link>
                    </div>
                    <div className="ind-blogers__item col">
                        <Link to="https://www.instagram.com/vnimiz/?igshid=MzRlODBiNWFlZA%3D%3D" target="_blank">
                            <div className="ind-blogers__img" style={{backgroundImage: "url('"+imgInst02+"')"}}></div>
                            <p> 
                                4k<br/>
                                подписчиков
                            </p>
                        </Link>
                    </div>
                    <div className="ind-blogers__item col">
                        <Link to="https://www.instagram.com/mama_v_dele_39_/?igshid=MzRlODBiNWFlZA%3D%3D" target="_blank">
                            <div className="ind-blogers__img" style={{backgroundImage: "url('"+imgInst03+"')"}}></div>
                            <p> 
                                18k<br/>
                                подписчиков
                            </p>
                        </Link>
                    </div>
                    <div className="ind-blogers__item col">
                        <Link to="https://www.instagram.com/deinega_dasha/?igshid=MzRlODBiNWFlZA%3D%3D" target="_blank">
                            <div className="ind-blogers__img" style={{backgroundImage: "url('"+imgInst04+"')"}}></div>
                            <p> 
                                12k<br/>
                                подписчиков
                            </p>
                        </Link>
                    </div>
                    <div className="ind-blogers__item col">
                        <Link to="https://www.instagram.com/sweeti_ylia/?igshid=MzRlODBiNWFlZA%3D%3D" target="_blank">
                            <div className="ind-blogers__img" style={{backgroundImage: "url('"+imgInst05+"')"}}></div>
                            <p> 
                                16k<br/>
                                подписчиков
                            </p>
                        </Link>
                    </div>
                    <div className="ind-blogers__item col">
                        <Link to="https://www.instagram.com/anna_ofchinnikova/?igshid=NTc4MTIwNjQ2YQ%3D%3D" target="_blank">
                            <div className="ind-blogers__img" style={{backgroundImage: "url('"+imgInst06+"')"}}></div>
                            <p> 
                                10k<br/>
                                подписчиков
                            </p>
                        </Link>
                    </div>
                    <div className="ind-blogers__item col">
                        <Link to="https://www.instagram.com/kira.rashevskaya/?igshid=NTc4MTIwNjQ2YQ%3D%3D" target="_blank">
                            <div className="ind-blogers__img" style={{backgroundImage: "url('"+imgInst07+"')"}}></div>
                            <p> 
                                17k<br/>
                                подписчиков
                            </p>
                        </Link>
                    </div>
                    <div className="ind-blogers__item col">
                        <Link to="https://www.instagram.com/svetlanazabotlivaya/?igshid=NTc4MTIwNjQ2YQ%3D%3D" target="_blank">
                            <div className="ind-blogers__img" style={{backgroundImage: "url('"+imgInst08+"')"}}></div>
                            <p> 
                                58k<br/>
                                подписчиков
                            </p>
                        </Link>
                    </div>
                    <div className="ind-blogers__item col">
                        <Link to="https://www.instagram.com/anzhelikavs/?igshid=YzcxN2Q2NzY0OA%3D%3D" target="_blank">
                            <div className="ind-blogers__img" style={{backgroundImage: "url('"+imgInst09+"')"}}></div>
                            <p> 
                                3k<br/>
                                подписчиков
                            </p>
                        </Link>
                    </div>
                </div> 
                <Link className="ba" to={'/registration/'}>Посмотреть всех блогеров</Link>
            </div>
        </section>
        </>
    )
}