import React, { useRef, ChangeEvent, useContext, useState, useEffect } from "react" 
import { GlobalContext } from "../../../context/GlobalContext" 
import { Link } from "react-router-dom"
import { Form } from "../../../component/Form" 
import axios from "axios"
import { Loader } from "../../../component/Loader"
import { FormatSelector } from "./FormatSelector"

export const SellerAccountPage = (props: any) => {
    const auth = useContext(GlobalContext)    

    const [load, setLoad] = useState(true)  
    const [loadForm, setLoadForm] = useState(true)  
    const [showFormat, setShowFormat] = useState(false)  
    const [popupType, setPopupType] = useState("") 

    const [fileName, setFileName] = useState("")  
 

    const [form, setForm] = useState({ 
        itemName: "",
        productName: "",
        soc: "-", 
        miniDesc: "",
        desc: "",
        link: "",
        file: "",
        maxIntegrations: "1",
        price: 1,
        tz: "",
        format1: false,
        format2: false,
        format3: false,
        format4: false,
        format5: false,
    })

    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }

    function handlerImage(e: any){
        setForm({ ...form, ["file"]: e.target.files[0] })
        setFileName(e.target.files[0].name)
    }

    useEffect(()=>{
        auth.user.AccountInfo().then(function(response){
            setLoad(false)
        }).catch(function(error){
            alert("error")
        })
    },[])
 
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault()
        if(loadForm){
            setLoad(false)
        
            const formData = new FormData() 

            formData.append("status", "w8_bloger")
            formData.append("title", form.itemName)
            formData.append("productTitle", form.productName)
            formData.append("productImage", form.file)
            formData.append("productLink", form.link)
            formData.append("miniDesc", form.miniDesc)
            formData.append("desc", form.desc)
            formData.append("maxIntegrations", form.maxIntegrations)
            formData.append("tz", form.tz)

            formData.append("price", `${form.price}`)


            formData.append("format1", `${form.format1}`)
            formData.append("format2", `${form.format2}`)
            formData.append("format3", `${form.format3}`)
            formData.append("format4", `${form.format4}`)
            formData.append("format5", `${form.format5}`)
  
            await axios.post(`https://api.blogerito.ru/integrations/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Token '+auth.user.userToken
                }
            }).then(res => {  
                setForm({ 
                    itemName: "",
                    productName: "",
                    soc: "-", 
                    miniDesc: "",
                    desc: "",
                    link: "",
                    file: "",
                    maxIntegrations: "1",
                    price: 0,
                    tz: "",
                    format1: false,
                    format2: false,
                    format3: false,
                    format4: false,
                    format5: false,
                })
                setPopupType(res.data.msg) 
            }).catch(error => {
    
                console.log(error)
                alert("error")
            })
            
        }
    }

    if(load){
        return(
            <Loader /> 
        )
    }

    return (
        <>
            <section className="new-program">
                <div className="content">  
                    <div className="row">
                        <div className="col-12">
                            <div className="form-item__wrapper">
                                <div className="">
                                    <h1 className="title" style={{color: "white"}}>Создайте проект:</h1>
                                    <form onSubmit={handleSubmit}>
                                        <label> 
                                            Название проекта
                                            <input 
                                                type="text" 
                                                maxLength={255}
                                                required 
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="itemName" 
                                                value={form.itemName} 
                                                onChange={changeHandler}
                                            />
                                        </label>
                                        <label> 
                                            Название товара
                                            <input 
                                                type="text" 
                                                maxLength={255}
                                                required 
                                                name="productName" 
                                                value={form.productName} 
                                                onChange={changeHandler}
                                            />
                                        </label>
                                        
                                        <label> 
                                            Количество возможных интеграций в рамках этого задания
                                            <input 
                                                required
                                                type="number" 
                                                min={1}
                                                step={1}
                                                value={form.maxIntegrations} 
                                                name="maxIntegrations" 
                                                onChange={changeHandler}
                                            /> 
                                        </label>

                                        <label style={{display: "none"}}> 
                                            <label> 
                                                Социальная сеть
                                                <select 
                                                    required
                                                    onChange={(e)=>{ 
                                                        setForm({ ...form, "soc": e.target.value })
                                                    }}
                                                >
                                                    <option value="" disabled >Выбрать</option>
                                                    <option value={"instagram"} selected>Instagram</option>
                                                    <option value={"youtube"}>Youtube</option>
                                                    <option value={"vk"}>Vk</option>
                                                    <option value={"rutube"}>Rutube</option>
                                                    <option value={"telegram"}>Telegram</option>
                                                    <option value={"any"}>Любая</option>
                                                </select>
                                            </label>
                                        </label>

                                        <FormatSelector form={form} setForm={setForm} showFormat={showFormat} setShowFormat={setShowFormat} />
                                         
                                        <label> 
                                            Короткое описание
                                            <input 
                                                type="text" 
                                                maxLength={510}
                                                required 
                                                name="miniDesc" 
                                                value={form.miniDesc} 
                                                onChange={changeHandler}
                                            />
                                        </label>
 
                                        <label>
                                            Цена товара <b style={{fontSize: 12, fontWeight: 300}}>(руб.)</b>
                                            <input 
                                                type="number"  
                                                name="price"
                                                required
                                                value={form.price}
                                                min={1}
                                                onChange={changeHandler}  
                                            />
                                        </label>

                                        <label> 
                                            Описание товара
                                            <textarea required  name="msg" value={form.desc} onChange={e => setForm({ ...form, ["desc"]: e.target.value })} />
                                        </label>

                                        
                                        <label>
                                            Техническое задание на покупку товара <b style={{fontSize: 12, fontWeight: 300}}>(будет автоматически выслано блогеру после приема заявки)</b>
                                            <input 
                                                type="text" 
                                                maxLength={510}
                                                required
                                                name="tz" 
                                                value={form.tz} 
                                                onChange={changeHandler}  
                                            />
                                        </label>

                                        <label>
                                            Ссылка на рекламируемую страницу <b style={{fontSize: 12, fontWeight: 300}}>(можно оставить пустым)</b>
                                            <input 
                                                type="text" 
                                                maxLength={510}
                                                name="link" 
                                                value={form.link} 
                                                onChange={changeHandler}  
                                            />
                                        </label>

                            <div style={{
                                marginBottom: 30,
                                backgroundColor: "red",
                                color: "white",
                                padding: "10px 20px",
                                borderRadius: 10,
                                marginTop: "-10px",
                                fontSize: "15px",
                                textAlign: "center",
                                textTransform: "uppercase"
                            }}>
                                Не давайте прямую ссылку на ваш товар, <br/>
                                сделайте тз на поиск вашего товара в гугл доке и разместите ссылку на этот документ
                            </div>

                                        {
                                            form.file == "" ? (
                                                <label style={{cursor: "pointer"}}>
                                                    <div className="dop-file project">
                                                        Прикрепите фото товара
                                                        <input 
                                                            type="file"  
                                                            required  
                                                            name="file" 
                                                            onChange={handlerImage} 
                                                            accept="image/*"
                                                        />
                                                    </div>
                                                </label>
                                            ):(
                                                <div>
                                                    <div className="dop-file project">
                                                        {fileName} <span 
                                                        onClick={()=>{
                                                            setForm({ ...form, ["file"]: "" })
                                                            setFileName("")
                                                        }}
                                                        style={{
                                                            color: "red",
                                                            display: "inline-block",
                                                            width: "auto",
                                                            paddingLeft: "10px",
                                                            cursor: "pointer"
                                                        }}>X</span>
                                                    </div>
                                                </div>
                                            )
                                        }

                                           
                                        <button>Создать проект</button> 
                                    </form>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </section>

            <section className="project-list">
                <div className="content">
                    <h2 className="title">История интеграций:</h2>
                    {
                        auth.user.applicationsActive.length ? (
                            auth.user.applicationsActive.map(item=>{
                                return(
                                    <Link to={`/integration/${item.integration.pk}/`}>
                                        <div className="project-list__item">
                                            <div className="logo" style={{backgroundImage: `url(https://api.blogerito.ru${item.integration.seller.image})`}}></div>
                                            <div className="project-list__content">
                                                <h3>
                                                    {item.integration.title}
                                                    <span>{item.date}</span>
                                                </h3>
                                                <p>{item.integration.miniDesc}</p>
                                            </div>
                                        </div>
                                    </Link>
                                )
                            })
                        ):(
                            <div>
                                <h3>Список пуст</h3>
                            </div>
                        )
                    }                     
                     
                    <div style={{textAlign: "center"}}>
                        <Link style={{display: "inline-block"}} to="/story/" className="myprogramms__morebtnitem" >Посмотреть все</Link>
                    </div> 
                </div>
            </section>

            <Form />

            { 
                popupType != "" ? (                                 
                    <div className="popup-msg">
                        <div className="popup-msg__body">
                            <h3>{popupType}</h3>
                            <div className="popup-msg__link" onClick={()=>{ 
                                setPopupType("") 
                            }}>
                                Продолжить
                            </div>
                        </div>
                    </div>
                ):""
            }     
        </>
    )
}