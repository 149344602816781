import React, { ChangeEvent, useContext } from "react"
import { GlobalContext } from "../context/GlobalContext" 

export const NotificationForm = (props: any) => {
    const auth = useContext(GlobalContext)   
    
    const changeHandlerNotifications = (event: ChangeEvent<HTMLInputElement>) => {
        props.setNotifications({ ...props.notifications, [event.target.name]: event.target.value })
    }

    const handleSubmitNotification = (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault()
        if(props.loadForm){
            props.setLoadForm(false)
            console.log(props.notifications)
            auth.user.typeObject!.SaveNotification(props.notifications).then(function(response){
                props.setPopupType(response.msg)
                props.setPopupError(response.error)
                props.setLoadForm(true)
            }).catch(function(error){
                alert("error")
            })
        }
    }

    return (
        <>
            <h2 className="title" style={{marginTop: 42}}>Уведомления</h2>  
            <div className="form-item__wrapper">
                <form onSubmit={handleSubmitNotification}>
                    <label>
                        Куда отправлять уведомления:
                        <select onChange={(e)=>{ 
                            props.setNotifications({ ...props.notifications, "notificationsType": e.target.value })
                        }}>
                            <option value={"all"} selected={props.notifications.notificationsType == "all"}>Telegram и E-mail</option>
                            <option value={"telegram"} selected={props.notifications.notificationsType == "telegram"}>Telegram</option>
                            <option value={"email"} selected={props.notifications.notificationsType == "email"}>E-mail</option>
                            <option value={"none"} selected={props.notifications.notificationsType == "none"}>Не отправлять</option>
                        </select>
                    </label>
                    <label>
                        Почта для уведомлений
                        <input type="email" name="notificationsEmail" value={props.notifications.notificationsEmail} onChange={changeHandlerNotifications} />
                    </label>
                    <button>Обновить</button>  
                </form>

                <div className="telegram__sub">
                    { 
                            auth.user.typeObject!.chatID != "" ? (
                                <p className="telegram__unlink">
                                    Отвязать аккаунт Telegram от учетной записи: <span onClick={()=>{
                                        if(props.loadForm){
                                            props.setLoadForm(false)
                                            auth.user.UnlinkTelegram().then(function(response){
                                                props.setPopupType(response.msg)
                                                props.setPopupError(response.error)
                                                props.setLoadForm(true)
                                            }).catch(function(error){
                                                props.setLoadForm(true)
                                                alert("error")
                                            })
                                        }
                                    }}>Отвязать</span>
                                </p>
                            ):(
                                <> 
                                    <p>Если приложение Telegram установлено, перейдите по ссылке:</p>
                                    <button onClick={()=>{
                                        window.location.assign(`tg://resolve?domain=blogerito_bot&start=${auth.user.typeObject?.secretKey}`);
                                    }}>Открыть приложение</button>
                                    <hr/>
                                    <p>Или отсканируйте QR-код с помощью камеры:</p>
                                    <img src={props.qrImage} />
                                </>
                            )
                    }
                </div>
            </div>
        </>
    )
}