import React, { useContext, useState } from "react"
import { GlobalContext } from "../../../context/GlobalContext" 

export const SellerVideoPage = () => {  
    const auth = useContext(GlobalContext)  
    const [video, setVideo] = useState([
        {
            "title": "Инструкция по работе с личным кабинетом сервиса Blogerito для блогеров",
            "frame": "https://www.youtube.com/embed/Qgn8mdUPSmc?si=iotjIMZiTTUZuVhE"
        },
        {
            "title": "Инструкция по использованию личного кабинета сервиса Blogerito для селлеров",
            "frame": "https://www.youtube.com/embed/-x9FkuO8_1A?si=0ldKqvPL-UYaNqVJ"
        },
    ])
    
    return( 
        <section className="video-list">
            <div className="content">
                <h1 className="title">Видеоуроки</h1>
                <div className="row">
                    {
                        video ? (
                            video.length ? (
                                video.map((item, index)=>{
                                    return (
                                        <div key={index} className="col-md-4">
                                            <div className="video-list__item">
                                                <iframe width="100%" height="315" src={item.frame}></iframe>                                                                            
                                                <p>{item.title}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            ):""
                        ):""
                    }
                </div>
            </div>
        </section>
    )
}