import React, { useContext, useState } from "react"
import { GlobalContext } from "../../../context/GlobalContext"
import greenscrine1 from "../../../image/greenscrine1.png" 

import db001 from "../../../image/db__001.png" 
import db002 from "../../../image/db__002.png" 


export const BlogerDBPage = () => {   
    const auth = useContext(GlobalContext) 
    const [update, setUpdate] = useState(false)
    const [question, setQuestion] = useState([
        {
            opener: false,
            image: db001,
            title: "Как правильно формулировать задачу на выкуп для блогера?",
            desc: "Выбери ключевую фразу, по которой хочешь продвигаться. И представь путь покупателя это вещи на WB или Озоне: он введет эту фразу в поиске, будет изучать разные карточки и только потом сделает выбор в пользу твоего артикула и положит его в корзину, а отзыв оставит не сразу - товаром же нужно попользоваться. Примерно так должен вести себя блогер, если ты хочешь организовать эффективный выкуп."
        },
        {
            opener: false,
            image: db002,
            title: "UTM-метки на Озоне + реклама товаров подписчикам блогера",
            desc: "В личном кабинете Озона в разделе Аналитика - Внешний траффик для отслеживания количества переходов с других сайтов можно создать ссылку с UTM-меткой. Эту ссылку можно передать блогеру для работы по формату - раздача товара подписчикам. Далее вам будет доступна сквозная аналитика по переходам через эту маркированную ссылку: количество переходов и заказов на озоне. Данный инструмент подходит только для Озона, на Вайлдберис, такого пока что нет."
        }
    ])
    
    React.useEffect(()=>{
        console.log("update") 
    },[update])

    return( 
        <>
        <section className="questions">
             <div className="content"> 
                 <h1 className="title">База знаний</h1>

                 <div className="questions-list">
                     {
                         question.map((item, index)=>{  
                             return (
                                <div key={index} className="db-item row">
                                    <div className="col-md-4">
                                        <div className="db-item__img" style={{backgroundImage: `url(${item.image})`}}></div>
                                    </div>
                                    <div className="col-md-8">
                                        <h3>{item.title}</h3>
                                        <p>{item.desc}</p>
                                    </div>
                                </div> 
                             )
                         })
                     }    
                 </div>
             </div>
         </section>
        </>
    )
}