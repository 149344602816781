export const Loader = (props: any) => {
    return (
        <div className="loadingBlock grid">
            <div> 
                <div className="loader">
                    <div className="inner one"></div>
                    <div className="inner two"></div>
                    <div className="inner three"></div>
                </div>
                <p>Загрузка</p>
            </div>
        </div>
    )
}